import { DeleteForever, Edit, ExpandMore, LockOutlined } from '@mui/icons-material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Tooltip, TablePagination, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Modal } from '@mui/material';
import React from 'react';
import { Batch, deleteBatch, fetchBatches, StoreToStore, StoreToUse } from '../../../services/batchesService';
import { resetNotifications } from '../../../services/notiStoreService';
import { NotiStore } from '../../../stores/notifications';
import { withRouter } from '../../utils/withRouter/withRouter';
import ProductionBatchesForm from '../ProductionBatchesForm/ProductionBatchesForm';
import './ProductionBatches.sass';
import { CSVLink } from 'react-csv';
import { toLocalISOTime } from '../../../services/stringConverter';

interface ProductionBatchesProps {
  searchParams: { [key: string]: string };
}
interface ProductionBatchesState {
  batches: Batch[];
  storesToStore: StoreToStore[];
  storesToUse: StoreToUse[];
  showPop: boolean;
  activeBatch: number;
  activeBatchIsDraft: boolean;
  showStoragePop: boolean;
  storagePopMode: string;
  batchesFormMode: string;
  showBatchesForm: boolean;
  page: number;
  rowsPerPage: number;
  pagination: { page: number, pageCount: number, pageSize: number, total: number },
  active: boolean,
  dataLoading: boolean
}

const defaultState = {
  batches: [],
  storesToStore: [],
  storesToUse: [],
  showPop: false,
  showStoragePop: false,
  storagePopMode: '',
  batchesFormMode: '',
  activeBatch: -1,
  activeBatchIsDraft: false,
  showBatchesForm: false,
  page: 0,
  rowsPerPage: 8,
  pagination: { page: 1, pageCount: 2, pageSize: 100, total: 100 },
  active: false,
  dataLoading:false
}

class ProductionBatches extends React.Component<ProductionBatchesProps, ProductionBatchesState> {
  state: ProductionBatchesState = { ...defaultState }

  componentDidMount() {
    resetNotifications()
    this.fetchData(true)
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.setState({ page: this.state.page === 0 ? newPage + 1 : newPage }, this.fetchData)

  };

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 }, this.fetchData)

  };

  fetchData = (checkSearchParams: boolean = false): void => {
    NotiStore.setState({ loading: true });

    let batches = fetchBatches(`pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}`);

    Promise.all([batches])
      .then(res => {

        this.setState({
          batches: res[0].data
        }, checkSearchParams ? () => {
          this.setState({ pagination: res[0].meta.pagination })
          // open batch on load
          let { id } = this.props.searchParams;
          if (id) {
            this.startEdit(Number(id), 'view')
          }
          // open batch on load end
        } : undefined)
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.' })
      })
      .finally(() => {
        NotiStore.setState({ loading: false })
      })
  }


  startEdit = (id: number, mode: string = ''): void => {
    this.setState({
      showBatchesForm: true,
      activeBatch: id
    })
  }

  handleOnClick = async (id: number) => {
    if (window.confirm('Möchten Sie diesen Stapel löschen?')) {
      const response = await deleteBatch(id)
      if (response.success) {
        NotiStore.setState({ loading: false, success: true, successMessage: 'Erfolgreich.' })

        this.fetchData()
      }


    }
  }

  ProduktlagerData(batch: any) {
    return (
      batch.attributes.storesToStore
        && batch.attributes.storesToStore.data
        && Array.isArray(batch.attributes.storesToStore.data)
        && batch.attributes.storesToStore.data.length >= 1
        ? batch.attributes.storesToStore.data.map((store: any, j: number) =>
        (store.attributes.name
          ? store.attributes.name
          : 'FEHLER'))
        : 'n/a')
  }

  VerarbeiteteWareData(batch: any) {
    return (
      Array.isArray(batch.attributes.goodsToUse.data) && batch.attributes.goodsToUse.data.length >= 1 ? (
        batch.attributes.goodsToUse.data.map((good: any, j: any) => (batch.attributes.status !== 'finished' || (batch.attributes.status === 'finished' && batch.attributes.goodsToUseData.filter((g: { id: number }) => g.id === good.id)[0].inputDone)) ? (
          batch.attributes.goodsToUseData.filter((g: { id: number }) => g.id === good.id).length >= 1 && batch.attributes.goodsToUseData.filter((g: { id: number }) => g.id === good.id)[0].inputQuantity > 0 &&
          good.attributes.customId.concat(" - ").concat(good.attributes.typeIntern).concat(good.attributes.warehouse_spot.data && batch.attributes.status !== 'finished' ? ` (${good.attributes.warehouse_spot.data.attributes.name})` : '').concat("  ")
        ) : null)
      ) : 'n/a'
    )
  }

  VerwendeteBetriebsmittel(batch: any) {
    return (
      batch.attributes.utilitiesUsed.data.length >= 1 && batch.attributes.utilitiesUsed.data.map((util: any, i: number) => (
        `(${util.attributes.customId} -  ${util.attributes.typeIntern}),`
      )))
  }

  Chargenwechsel(batch: any) {
    return (
      `Chargenwechsel= ${batch.attributes.batchChangeBagsCount ? batch.attributes.batchChangeBagsCount :"n/a"},  Bigbags=${batch.attributes.batchChangeStore?.data?.attributes?.name ? batch.attributes.batchChangeStore?.data?.attributes?.name:"n/a"}),`
    )
  }

  onModalClose() {
    this.setState({ active: true })
  }

  fetchDataForExport = (): void => {

    this.setState({dataLoading:true})
    let batches = fetchBatches(``);

    Promise.all([batches])
      .then(res => {

        this.setState({
          batches: res[0].data
        })
        this.setState({dataLoading:false})
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.' })
      })
      .finally(() => {
      })
  }

  handleExport = () => {
    this.setState({ active: true, batches: [] })
    this.fetchDataForExport()
  }

  totalKg(data: any) {
    return data.reduce((total: any, item: any) => total + item.attributes.origQuantity, 0) + " kg";
  }

  render() {
    return (
      <div className="ProductionBatches content" data-testid="ProductionBatches">
        <div className="header-bar">
          <h1>Chargen</h1>
          {this.state.batches.length >= 1 &&
            <>
              <Button variant='contained' onClick={() => this.handleExport()}>Daten exportieren</Button>
              <Button variant="contained" type='submit' onClick={() => this.setState({ showBatchesForm: true })}>Charge erstellen</Button>
            </>}

        </div>
        {this.state.batches.length >= 1 &&
          <TablePagination
            component={"div"}
            count={this.state.pagination.total}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />}
        {this.state.batches.length >= 1 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Bezeichnung</TableCell>
                  <TableCell>Produktlager</TableCell>
                  {/* <TableCell>Warenlager</TableCell> */}
                  <TableCell>Verarbeitete Ware</TableCell>
                  <TableCell>Start</TableCell>
                  <TableCell>Ende</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.batches.map((batch: Batch, i: number) => (
                  <TableRow key={`batch-${i}`}>
                    <TableCell>
                      {batch.attributes.status === 'planned' && <Tooltip title="geplant"><div className='swatch yellow'></div></Tooltip>}
                      {batch.attributes.status === 'active' && <Tooltip title="aktiv"><div className='swatch orange'></div></Tooltip>}
                      {batch.attributes.status === 'finished' && <Tooltip title="abgeschlossen"><div className='swatch green'></div></Tooltip>}
                    </TableCell>
                    <TableCell>
                      {batch.attributes.isDraft ? <span className='pointer' onClick={() => this.startEdit(batch.id)}>Entwurf</span> : (
                        <>
                          <strong className='pointer' onClick={() => batch.attributes.status === 'planned' ? this.startEdit(batch.id) : this.startEdit(batch.id, 'view')}>{batch.attributes.customId}</strong>
                        </>
                      )}
                    </TableCell>
                    <TableCell>{batch.attributes.type || 'n/a'}</TableCell>
                    <TableCell>
                      {batch.attributes.storesToStore && batch.attributes.storesToStore.data && Array.isArray(batch.attributes.storesToStore.data) && batch.attributes.storesToStore.data.length >= 1 ? batch.attributes.storesToStore.data.map((store, j) => (
                        <React.Fragment key={`good-stores-${j}`}><span>{store.attributes.name ? store.attributes.name : 'FEHLER'}</span><br /></React.Fragment>
                      )) : 'n/a'}
                    </TableCell>
                    {/* <TableCell>
                      {Array.isArray(batch.attributes.goodsToUse.data) && batch.attributes.goodsToUse.data.length >= 1 ? (
                        <React.Fragment>
                          {batch.attributes.goodsToUse.data.map((good, j) => (batch.attributes.status !== 'finished' || (batch.attributes.status === 'finished' && batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id)[0].inputDone)) ? (
                            <>{batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id).length >= 1 && batch.attributes.goodsToUseData.filter((g: {id: number}) => g.id === good.id)[0].inputQuantity > 0 && <React.Fragment key={`good-store-${j}`}><span>{good.attributes.warehouse_spot.data ? good.attributes.warehouse_spot.data.attributes.name : 'nicht gelagert'}</span><br /></React.Fragment>}</>
                          ) : null)}
                        </React.Fragment>
                      ) : 'n/a'}
                    </TableCell> */}
                    <TableCell>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Verarbeitete Ware View
                        </AccordionSummary>
                        <AccordionDetails>

                          {Array.isArray(batch.attributes.goodsToUse.data) && batch.attributes.goodsToUse.data.length >= 1 ? (
                            <React.Fragment>
                              {batch.attributes.goodsToUse.data.map((good, j) => (batch.attributes.status !== 'finished' || (batch.attributes.status === 'finished' && batch.attributes.goodsToUseData.filter((g: { id: number }) => g.id === good.id)[0].inputDone)) ? (
                                <>{batch.attributes.goodsToUseData.filter((g: { id: number }) => g.id === good.id).length >= 1 && batch.attributes.goodsToUseData.filter((g: { id: number }) => g.id === good.id)[0].inputQuantity > 0 && <React.Fragment key={`good-type-${j}`}><span>{good.attributes.customId} - {good.attributes.typeIntern}{good.attributes.warehouse_spot.data && batch.attributes.status !== 'finished' ? ` (${good.attributes.warehouse_spot.data.attributes.name})` : ''}</span><br /></React.Fragment>}</>
                              ) : null)}
                            </React.Fragment>
                          ) : 'n/a'}
                        </AccordionDetails>
                      </Accordion>

                    </TableCell>
                    <TableCell>{batch.attributes.start ? (`${new Date(batch.attributes.start).toLocaleDateString()} ${new Date(batch.attributes.start).toLocaleTimeString().replace(':00', '')}`) : 'n/a'}</TableCell>
                    <TableCell>{batch.attributes.end ? (`${new Date(batch.attributes.end).toLocaleDateString()} ${new Date(batch.attributes.end).toLocaleTimeString().replace(':00', '')}`) : 'n/a'}</TableCell>
                    <TableCell>
                      {batch.attributes.status === 'planned' ? <Edit className='warning action' onClick={() => this.startEdit(batch.id)} /> : <LockOutlined className='action' onClick={() => this.startEdit(batch.id, 'view')} />}
                    </TableCell>
                    <TableCell>
                      <div
                        onClick={() => this.handleOnClick(batch.id)}
                      >
                        <DeleteForever className='error action' />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Paper style={{ padding: '20px' }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="70vh"
              paddingLeft={0}
            >
              <CircularProgress />
            </Box>
            {/* Keine Chargen gefunden. */}
          </Paper>
        )}

        {
          this.state.batches.length >= 1 &&
          <ProductionBatchesForm
            open={this.state.showBatchesForm}
            activeBatch={this.state.activeBatch}
            closeModal={() => this.setState({ showBatchesForm: false, activeBatch: defaultState.activeBatch }, this.fetchData)}
          />
        }

        <Modal
          open={this.state.active}
          onClose={() => this.setState({ active: false })}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="NewLabReportModal"
        >
          <>
            <Box sx={modalStyle}>

              <CSVLink
                separator=';'
                filename={`Chargen-${toLocalISOTime(new Date())}.csv`}
                data={[['ID', 'Gewicht des Materials', 'Seitliches Produktgewicht',
                  'Anzahl der Laborproben', 'Chargenwechsel & Bigbags', 'Bezeichnung', 'Produktlager',
                  'Start', 'Ende','Verwendete Betriebsmittel', 'Verarbeitete Ware']].concat(this.state.batches.map(batch => {
                    return [
                      batch.attributes.isDraft ? "Entwurf" : batch.attributes.customId,
                      batch?.attributes?.output + "kg",
                      this.totalKg(batch.attributes.finishedProducts.data),
                      batch.attributes.labInterval,
                      this.Chargenwechsel(batch),
                      batch.attributes.type || 'n/a',
                      this.ProduktlagerData(batch),
                      batch.attributes.start ? (`${new Date(batch.attributes.start).toLocaleDateString()} ${new Date(batch.attributes.start).toLocaleTimeString().replace(':00', '')}`) : 'n/a',
                      batch.attributes.end ? (`${new Date(batch.attributes.end).toLocaleDateString()} ${new Date(batch.attributes.end).toLocaleTimeString().replace(':00', '')}`) : 'n/a',
                      this.VerwendeteBetriebsmittel(batch),
                      this.VerarbeiteteWareData(batch),

                    ]
                  }))}
              >
                {!this.state.dataLoading ? <Button variant='contained'>CSV-Export</Button> : "Moment, wir holen Daten ab..."}

              </CSVLink>
            </Box>
          </>
        </Modal>
      </div>
    )
  }
}
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '11%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
};
export default withRouter(ProductionBatches);
