import axios from "axios";
import { api } from "./apiService";
import { WarehouseSpot } from "./configService";
import { Good } from "./inventoryService";
import { getJWT, jwtRes } from "./jwtService";
import { UserData } from "./userService";

export interface fetchBatchesRes {
  success: boolean;
  data: Batch[];
  message?: string;
  meta:metaResponse
}
export interface metaResponse {
  pagination: { page: number; pageCount: number; pageSize: number; total: number; };
   page: number, pageCount : number ,pageSize : number ,total :  number
}
export interface Batch {
  id: number;
  attributes: {
    customId: string;
    type: string;
    isDraft: boolean;
    output: number;
    color: string;
    foodSafe: string;
    material: string;
    goodsName:string;
    batchChangeBagsCount: number;
    batchChangeStore: {data: WarehouseSpot};
    start: Date;
    end: Date;
    duration: number;
    labInterval: number;
    rpetCount: number;
    status: string;
    goodsToUse: {
      data: Good[]
    };
    storesToStore: {
      data: WarehouseSpot[]
    };
    storesToStoreData: {
      id: number;
      input: number;
    }[];
    goodsToUseData: {
      id: number;
      inputQuantity: number;
      inputDone: number;
      inputToDo: number;
    }[];
    utilitiesUsed: {data: Good[]};
    creator?: {data: {attributes: UserData}};
    finishedProducts: {data: Good[]}
    startedAt: Date;
    finishedAt: Date;
  }
}

export const emptyBatch: Batch = {
  id: -1,
  attributes: {
    customId: '',
    type: '',
    isDraft: false,
    output: -1,
    color: '',
    foodSafe: '',
    material: '',
    goodsName:'',
    batchChangeBagsCount: -1,
    batchChangeStore: {data: {id: -1, attributes: {name: '', description: '', capacity: -1, totalCapacity: -1}}},
    start: new Date(Date.now()),
    end: new Date(Date.now()),
    duration: -1,
    labInterval: -1,
    rpetCount: -1,
    status: '',
    goodsToUse: {
      data: []
    },
    storesToStore: {
      data: []
    },
    storesToStoreData: [],
    goodsToUseData: [],
    utilitiesUsed: {data: []},
    finishedProducts: {data: []},
    startedAt: new Date(),
    finishedAt: new Date(),
  }
}

export interface StoreToUse {
  id: number;
  input: number;
  storageName: string;
  goods: GoodToUse[]
}

export interface GoodToUse extends Good {
  inputQuantity?: number;
  inputDone?: number;
}

export interface StoreToStore extends WarehouseSpot {
  input: number;
}

export interface createBatchData {
  storesToUse: StoreToUse[]; 
  storesToStore: StoreToStore[]; 
  start: string; 
  labInterval: number; 
  batchChangeBagsCount: number; 
  batchChangeStore: string; 
  output: number;
  color: string;
  foodSafe: string;
  material: string;
  isDraft: boolean;
  id?: number;
  type: string;
  goodsName:string
}

export interface createBatchRes {
  success: boolean;
  message: string;
}

export let fetchBatches = (filters: string = '', sort: string = ''): Promise<fetchBatchesRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      axios.get(api + `/api/batches?sort[0]=${sort ? sort : 'createdAt%3Adesc'}${filters ? '&' + filters : ''}&populate[1]=storesToStore&populate[2]=batchChangeStore&populate[3]=goodsToUse&populate[4]=batchChangeStore&populate[5]=goodsToUse.warehouse_spot&populate[6]=utilitiesUsed&populate[7]=creator&populate[8]=finishedProducts&populate[9]=finishedProducts.warehouse_spot&populate[10]=finishedProducts.label&populate[11]=goodsToUse.batches`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data,
          meta:res.data.meta,
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: []
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let deleteBatch = (id: number): Promise<{success: boolean}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      axios.post(api + '/api/batches/delete-custom',{data:{id:id}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true
        });
      })
      .catch(err => {
        reject({});
      })
   
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });


}

export let createBatch = (data: createBatchData, isUpdate: boolean = false): Promise<createBatchRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      if (isUpdate) {
        axios.put(api + `/api/batches/${data.id ? data.id : -1}`, {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then(res => {
          resolve({
            success: true,
            message: 'success'
          })
        })
        .catch(err => {
          let message: string = '';

          try {
            message = err.response.data.error.message
          } catch {
            message = 'Fehler beim Aktualisieren der Charge.'
          }
          reject({
            success: false,
            message: message
          })
        })
      } else {
        axios.post(api + `/api/batches`, {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then(res => {
          resolve({
            success: true,
            message: 'success'
          })
        })
        .catch(err => {
          let message: string = '';

          try {
            message = err.response.data.error.message
          } catch {
            message = 'Fehler beim Erstellen der Charge.'
          }
          reject({
            success: false,
            message: message
          })
        })
      }
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}