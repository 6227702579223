import axios from "axios";
import { api } from "./apiService";
import { Good } from "./inventoryService";
import { getJWT, jwtRes } from "./jwtService";
import { Report } from "./laboratoryService";
import { UserData } from "./userService";

export const supplierOptions: {id: string, title: string}[] = [
  {id: "supplier", title: "Lieferant"},
  {id: "address", title: "Adresse"},
  {id: "zip", title: "PLZ"},
  {id: "cityAndCountry", title: "Stadt, Land"},
  {id: "contactPerson", title: "Kontaktperson"},
  {id: "email", title: "Email"},
  {id: "phone", title: "Telefon"},
]

export interface fetchOrdersResponse {
  success: boolean;
  data?: {
    id: number;
    attributes: OrderData;
  }[];
  message?: string;
  meta:metaResponse
}

export interface fetchOrderResponse {
  success: boolean;
  order?: {
    id: number,
    attributes: OrderData
  };
  message?: string;
}

export interface saveOrderResponse {
  success: boolean;
  message?: string;
}
export interface metaResponse {
  pagination: { page: number; pageCount: number; pageSize: number; total: number; };
   page: number, pageCount : number ,pageSize : number ,total :  number
}
export interface OrderData {
  customId?: string;
  isDraft?: boolean;
  isCancelled?: boolean;
  readOnly?: boolean;
  isFulfilled: boolean;
  supplier: string;
  address: string;
  zip: string;
  cityAndCountry: string;
  contactPerson: string;
  email: string;
  phone: string;
  reference: string;
  positions: OrderPosition[];
  creator?: {data: {attributes: UserData}};
  goods?: {data: Good[]},
}

export interface OrderPosition {
  typeOffer: string;
  typeIntern: string | undefined;
  category: string;
  quantity: number | null;
  unit: string;
  price: number | null;
  priceUnit: string;
  total: number;
  color: string;
  foodSafe: string;
  material: string;
  trucksCount: number | undefined;
  plannedEntryAmount: number | undefined;
  packaging: string | undefined;
  truckData?: TruckData[];
  readOnly?: boolean;
}

export interface EntryPos {
  type: string;
  color: string;
  foodSafe: string;
  material: string;
  quantity: number;
  unit: string;
  packaging: string;
  category: string;
  storagePlace: number;
  goods?: Good[];
  reports?: Report[];
}

export interface DeliveredPos {
  type: string;
  unit: string;
  color: string;
  goods: Good[];
  reports?: Report[];
  category: string;
  foodSafe: string;
  material: string;
  quantity: number;
  packaging: string;
  storagePlace: number;
}

export interface TruckData {
  storagePlace?: number;
  truckAmount?: string;
  truckDeliveryDate?: string;
  actualDeliveryDate?: string;
  truckPlate?: string;
  loadType?: string;
  reportsCount?: number;
  start?: string;
  end?: string;
  fulfilled?: boolean;
  deliveredPositions?: DeliveredPos[];
  notes?: string;
}

export interface TruckUnloadRes {
  success: boolean;
  data: {url: string}[];
  message?: string;
 
}

export interface TruckNotesRes {
  success: boolean;
  message?: string;
}

export let fetchOrders = (filters: string = '', sort: string = ''): Promise<fetchOrdersResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      axios.get(api + '/api/orders?' + (filters ? ('&' + filters) : '') + `&sort[0]=${sort ? sort : 'createdAt%3Adesc'}&populate=*`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          meta:res.data.meta,
          data: res.data.data,
        })
      })
      .catch(err => {
        reject({
          success: false
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered'
      })
    })
  })
}

export let deleteOrder = (id: number): Promise<{success: boolean}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      axios.post(api + '/api/order/delete-custom',{data:{id:id}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true
        });
      })
      .catch(err => {
        reject({});
      })
   
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let fetchOrder = (id: number): Promise<fetchOrderResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      axios.get(api + '/api/orders/' + id + '?populate[0]=goods&populate[1]=goods.batches&populate[2]=goods.exit_orders', {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          order: res.data.data
        })
      })
      .catch(err => {
        reject({
          success: false
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'catch triggered'
      })
    })
  })
}


export let saveRestoreOrder = (id:any,email:string): Promise<saveOrderResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

        // TODO: create custom endpoint
        axios.put(api + '/api/orders/restore', {orderId:id,userLoginEmail:email}, { headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then((res) => {
          resolve({
            success: true
          })
        })
        .catch(err => {
          reject({
            success: false,
            message: err.response.data.error.message || 'Fehler beim Speichern der Bestellung'
          })
        })
     
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}
export let saveOrder = (data: OrderData, isUpdate: boolean = false, reportId: number = -1): Promise<saveOrderResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })

      if (isUpdate && reportId >= 0) {
        // TODO: create custom endpoint
        axios.put(api + '/api/orders/' + reportId, {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then((res) => {
          resolve({
            success: true
          })
        })
        .catch(err => {
          reject({
            success: false,
            message: err.response.data.error.message || 'Fehler beim Speichern der Bestellung'
          })
        })
      } else {
        axios.post(api + '/api/orders', {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
        .then((res) => {
          resolve({
            success: true
          })
        })
        .catch(err => {
          if (err.response.data.error.message && err.response.data.error.message === 'email must be a valid email') err.response.data.error.message = 'Bitte gib eine gültige Emailadresse an.';
          
          reject({
            success: false,
            message: err.response.data.error.message ? err.response.data.error.message : 'Fehler beim Erstellen der Bestellung.'
          })
        })
      }
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let onTruckUnload = (orderId: number, posId: number, truckId: number, divisionData: {pos: number, quantity: number}[], positions: EntryPos[], weightWasCalculated: boolean = false): Promise<TruckUnloadRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      let data = {orderId, posId, truckId, divisionData, positions, weightWasCalculated};
      
      axios.post(api + '/api/goods/handleTruckUnload', {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          data: res.data,
        })
      })
      .catch(err => {
        reject({success: false, data: [], message: err.response.data?.error && err.response.data?.error.message ? err.response.data?.error.message : undefined})
      })
    })
    .catch((err) => {
      reject({
        success: false,
        data: [],
        message: 'JWT error'
      })
    })
  });
}

export let updateTruckNotes = (orderId: number, posId: number, truckId: number, truckNotes: string): Promise<TruckNotesRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      let data = {orderId, posId, truckId, truckNotes};
      
      axios.put(api + '/api/order/update-truck-notes', {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
        })
      })
      .catch(err => {
        reject({success: false, data: [], message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message : undefined})
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let cancelOrder = (id: number): Promise<{success: boolean, message: string}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      let data = {
        id
      };
      
      axios.post(api + '/api/order/cancel', {data: data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then(res => {
        resolve({
          success: true,
          message: '',
        })
      })
      .catch(err => {
        reject({
          success: false, 
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message : undefined
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let fetchOrdersWithFilters = (filters: {[key: string]: string[]}, sortBy: {[key: string]: string} = {}): Promise<fetchOrdersResponse> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.post(api + `/api/order/custom-filter?populate=*`, {data: {filters, sortBy}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data || [],
          message: '',
          meta:res.data.meta
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: [],
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let updateDeliveredPositions = (orderId: number, posId: number, truckId: number, positions: EntryPos[]): Promise<{
  success: boolean;
  message?: string;
}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      axios.put(api + `/api/order/update-delivered-positions`, {data: {orderId, posId, truckId, positions}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          message: ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let finishTruck = (orderId: number, posId: number, truckId: number): Promise<{
  success: boolean;
  message?: string;
}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found'
      })
      
      //do stuff here
      axios.put(api + `/api/order/finish-truck`, {data: {orderId, posId, truckId}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          message: ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error'
      })
    })
  });
}

export let updateTotals = (orderId: number, positions: {total: number, truckData: TruckData | undefined}[]): Promise<{
  success: boolean;
  message: string;
  data: {total: number | string; price: number | string;}[] | undefined;
}> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: undefined
      })
      
      //do stuff here
      axios.put(api + `/api/order/update-totals`, {data: {orderId, positions}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          message: '',
          data: res.data || []
        })
      })
      .catch(err => {
        reject({
          success: false,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.',
          data: undefined
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: undefined
      })
    })
  });
}

