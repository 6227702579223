import { Box, Button, Chip, CircularProgress, InputAdornment, MenuItem, Modal, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { resetNotifications } from '../../../services/notiStoreService';
import NewOrderForm from '../NewOrderForm/NewOrderForm';
import './WarehouseEntry.sass';
import { NotiStore } from '../../../stores/notifications';
import { deleteOrder, fetchOrder, fetchOrders, fetchOrdersResponse, fetchOrdersWithFilters, OrderData, TruckData } from '../../../services/warehouseEntriesService';
import { ArrowDownward, CalendarMonth, Close, DeleteSharp, Edit, Error, ExpandMore, FilterList, InfoOutlined, LockOutlined, QrCode2, VisibilityOutlined } from '@mui/icons-material';
import { calcTotalOfPos, formatter, getTotalOfOrder } from '../../../services/calculator';
import { convertDateString, convertGoodsCategory, convertOrderAttributes, localCurrency, localNumber, localNumberInTons, translate } from '../../../services/stringConverter';
import { fetchWarehouseData, FetchWarehouseDataResponse, WarehouseSpot } from '../../../services/configService';
import FilterPop from '../../utils/FilterPop/FilterPop';
import { withRouter } from '../../utils/withRouter/withRouter';
import { checkRightsPrice, checkRightsPriceOrder } from '../../../services/utilitiesService';
import { Link } from 'react-router-dom';
import { IncommingSort } from '../../../utils/defaults';
import { Good } from '../../../services/inventoryService';

interface WarehouseEntryProps {
  searchParams: {[key: string]: string};
  rights: string[];
  user:any
}
interface WarehouseEntryState {
  entries: {
    id: number,
    attributes: OrderData
  }[];
  warehouseSpots: WarehouseSpot[];
  showNewOrderModal: boolean;
  modalEditDraftMode: boolean;
  modalEditDraftData: {id: number, attributes: OrderData} | null;
  modalMode: string;
  tableExpandedPos: number[];
  showFilterPop: number;
  filters: {
    [key: string]: string[];
  };
  activeFilters: boolean;
  sortBy: {
    [key: string]: string;
  };
  statusFilter: string;
  page:number,
  rowsPerPage:number,
  pagination:{ page: number, pageCount : number ,pageSize : number ,total :  number},
  sort:string,
  search:string,
  activeGood: Good | undefined,
  enableUpdate: string,
  showDetailPop: boolean,
  
}

const defaultState = {
  entries: [],
  warehouseSpots: [],
  showNewOrderModal: false,
  modalEditDraftMode: false,
  modalEditDraftData: null,
  modalMode: '',
  tableExpandedPos: [],
  showFilterPop: -1,
  filters: {},
  activeFilters: false,
  sortBy: {},
  statusFilter: '',
  page:0  ,
  rowsPerPage:8,
  pagination:{ page: 1, pageCount : 8 ,pageSize : 8 ,total :  100},
  sort:"select",
  search:'',
  activeGood: undefined,
  enableUpdate: '',
  showDetailPop: false,
  
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

class WarehouseEntry extends React.Component<WarehouseEntryProps, WarehouseEntryState> {
  state: WarehouseEntryState = {...defaultState}

  componentDidMount() {
    resetNotifications();
    this.fetchData(true);
  }
  
  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.setState({page: this.state.page === 0 ? newPage + 1 : newPage },this.fetchData)
    
  };
  
  handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.setState({page: 0}, this.fetchData)
     
      };
      handleFilter = (type:string,value:string): void => {
      
        NotiStore.setState({ loading: true })
        if(type==='search'){
          this.setState({search:value})
          this.fetchData(false,value,'')
        }else{
            this.fetchData(false,'',value) 
            this.setState({sort:value})
        }
    
      }
    
      clearFilters = (): void => {
        this.setState({ activeFilters: false, filters: {} ,sort:'select',search:'' })
        this.fetchData();
      }

  fetchData = (checkSearchParams: boolean = false,search='',sort=''): void => {
    NotiStore.setState({loading: true});

    fetchOrders(`_q=${search}&pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}`, sort)
    .then((res: fetchOrdersResponse) => {
      fetchWarehouseData()
      .then((res2: FetchWarehouseDataResponse) => {
        let tableExpandedPos: number[] = [];
        this.setState({ pagination: res.meta.pagination})
        if (res.data && res.data.length >= 1) res.data.forEach((o, i) => tableExpandedPos.push(i))

        this.setState({
          entries: res.data || [],
          warehouseSpots: res2.spots || [],
          tableExpandedPos,
        }, checkSearchParams ? () => {
          // open order on load
          let {id} = this.props.searchParams;
          if (id) {
            this.setState({modalMode: 'view'})
            this.editDraft(Number(id))
          }
          // open order on load end
        } : undefined)
      })
      .catch(() => {
        NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Lagerdaten ist ein Fehler aufgetreten.', loading: false})
      })
      .finally(() => {
        NotiStore.setState({loading: false});
      })
    })
    .catch(() => {
      NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Bestellungen ist ein Fehler aufgetreten.', loading: false});
    })
  }

  closeNewOrderModal = (): void => {
    this.setState({showNewOrderModal: false, modalEditDraftMode: false, modalEditDraftData: null, modalMode: ''});
  }

  handleIncommingPop = (good:any): void => {

    this.setState({showDetailPop:true,activeGood:good});
  }
  handleIncommingCloasePop = (): void => {
    this.setState({showDetailPop:false,activeGood:undefined});
  }
  editDraft = (id: number): void => {
    fetchOrder(id)
    .then((res) => {
      if (!res.order?.attributes) {
        NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.'})
      } else {
        this.setState({
          modalEditDraftMode: true,
          modalEditDraftData: res.order,
          showNewOrderModal: true,
        })
      }
    })
    .catch(() => NotiStore.setState({error: true, errorMessage: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.'}))
  }

  expandOrder = (i: number): void => {
    if (i >= 0) {
      let {tableExpandedPos} = this.state

      if (tableExpandedPos.includes(i)) {
        let index = tableExpandedPos.indexOf(i)

        if (index > -1) {
          tableExpandedPos.splice(index, 1)
        }
      } else {
        tableExpandedPos.push(i)
      }

      this.setState({tableExpandedPos})
    }
  }

  showFilterPop = (id: number): void => {
    if ((id && id > 0) || id === 0) {
      let {showFilterPop} = this.state;

      if (showFilterPop !== id) showFilterPop = id
      else {
        showFilterPop = defaultState.showFilterPop
      }

      this.setState({showFilterPop})
    }
  }

  submitFilters = (): void => {
    NotiStore.setState({loading: true})

    let {filters, sortBy} = this.state

    this.setState({activeFilters: true})

    fetchOrdersWithFilters(filters, sortBy)
    .then(res => {
      this.setState({entries: res.data || []});
      NotiStore.setState({loading: false})
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: err.message || 'Fehler beim Abrufen der Bestellungen aufgetreten.', loading: false})
    })
  }



  updateFilter = (e: React.ChangeEvent<HTMLInputElement>, key: string, multiple: string = ''): void => {
    let {filters} = this.state;

    if (multiple === 'multiple') {
      if (!filters[key]) filters[key] = [e.target.value]
      else {
        if (!filters[key].includes(e.target.value)) filters[key].push(e.target.value)
        else {
          let index = filters[key].indexOf(e.target.value)
          if (index > -1) filters[key].splice(index, 1)
        }
      }
    } else {
      if (e.target.value) filters[key] = [e.target.value]
      else delete filters[key]
    }

    this.setState({filters})
  }

  handleSort = (value: string): void => {
    if (value) {
      NotiStore.setState({loading: true})

      let {sortBy} = this.state
      if (sortBy.id === value) {
        if (sortBy.direction === 'desc') this.setState({sortBy: {id: value, direction: 'asc'}}, this.submitFilters)
        else if (sortBy.direction === 'asc') this.setState({sortBy: {id: value, direction: 'desc'}}, this.submitFilters)
      } else {
        this.setState({sortBy: {id: value, direction: 'asc'}}, this.submitFilters)
      }

      NotiStore.setState({loading: false})
    }
  }

  getTableTotal = (): {quantity: string, value: string} => {
    let data = {
      quantity: 'n/a',
      value: 'n/a',
    }

    let quantity: number = 0
    let value: number = 0

    if (this.state.entries.length >= 1) this.state.entries.forEach((order) => {
      if (!order.attributes.isDraft && !order.attributes.isCancelled && order.attributes.positions && order.attributes.positions.length >= 1) order.attributes.positions.forEach(pos => {
        if (pos.quantity && pos.unit === 'kg') quantity += Number(pos.quantity)
        else if (pos.quantity && pos.unit === 't') quantity += (Number(pos.quantity)*1000)

        if (pos.total) value += pos.total
      })
    })

    data.quantity = `${localNumber(quantity / 1000)} t`
    data.value = `${localCurrency(value)} €`

    return data
  }

  checkStoreCapacity = (data: TruckData): boolean => {
    let result: boolean = false
    let deliveredAmount: number = 0

    if (data.deliveredPositions && data.deliveredPositions.length >= 1) data.deliveredPositions.forEach(dPos => {
      if (dPos.goods && dPos.goods.length >= 1) dPos.goods.forEach(dGood => {
        if (dGood.attributes.origQuantity) deliveredAmount += dGood.attributes.origQuantity
      })
    })

    if (data.truckAmount && data.storagePlace) {
      let truckAmount = Number(data.truckAmount)
      let {storagePlace} = data

      let matches = this.state.warehouseSpots.filter(spot => spot.id === storagePlace)

      if (matches.length === 1) {
        let store = matches[0]

        if (store.attributes.capacity >= (truckAmount - deliveredAmount)) result = true
      }
    } else {
      result = true
    }

    return result
  }
 
 async handleIncommingOrderDelete(orderId:number){
    if (window.confirm('Löschen Sie diese eingehende Bestellung?')) {
      const response = await deleteOrder(orderId)
      if (response.success) {
        NotiStore.setState({ loading: false, success: true, successMessage: 'Erfolgreich.' })

        this.fetchData()
      }


    } 
  }
  render() {
    return (
      <div className="WarehouseEntry content" data-testid="WarehouseEntry">
        <div className='header-bar'>
          <h1>
            Incoming
            <Link to={'/wareneingang/kalender'} style={{marginLeft: 10}}><Button variant='contained' color='secondary'><CalendarMonth /></Button></Link>
          </h1>
          <Button variant="contained" onClick={() => this.setState({showNewOrderModal: true, modalMode: 'create'})}>
            Bestellung erstellen
          </Button>
        </div>
          <div style={{ marginTop: 10,marginBottom:20 }}>
            <TextField value={this.state.search} placeholder='Search...' onChange={(e)=>this.handleFilter('search',e.target.value)} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            label="Sort"
            value={this.state.sort}
            input={<OutlinedInput />}
            onChange={(e) => this.handleFilter('sort',e.target.value)}
            // onClose={()=>this.handleFilter('sort','')}
            sx={{ width: '300px' }}
          >
           
            {IncommingSort.map((status,i) => (
              <MenuItem key={i} value={status.value}>
                {status.Label}
              </MenuItem>
            ))}

          </Select>&nbsp;&nbsp;
          {/* <Button onClick={()=>this.handleFilter('sort','')} variant='contained'>Sort</Button>&nbsp;&nbsp; */}
          <Button onClick={()=>this.clearFilters()} variant='contained' color="error">Clear</Button>
          </div>
        {this.state.activeFilters ? (
          <Chip color='error' className='pointer' sx={{marginBottom: '15px'}} label={<span className='flex align-center'><Close sx={{fontSize: '14px', marginRight: '10px'}} />Filter zurücksetzen</span>} onClick={this.clearFilters} />
        ) : null}
 {
          this.state.entries.length >=1 && 
          <TablePagination
            component={"div"}
            count={this.state.pagination.total}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        }
        {this.state.entries.length >= 1 ? <TableContainer className='orders-table table-container' component={Paper}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
              <TableCell>
                      <span>Creator</span>
                      
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <div className='flex align-center nowrap'>
                        <span className='strong'></span>
                      </div>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 8 ? 'active' : ''}`} onClick={() => this.showFilterPop(8)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='orderStatus'
                        active={this.state.showFilterPop === 8}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>ID</span>
                      <div>
                        <ArrowDownward className='pointer icon' onClick={() => this.handleSort('customId')} />
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 0 ? 'active' : ''}`} onClick={() => this.showFilterPop(0)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='customId'
                        active={this.state.showFilterPop === 0}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>            
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>description</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 2 ? 'active' : ''}`} onClick={() => this.showFilterPop(2)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='typeIntern'
                        active={this.state.showFilterPop === 2}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>category</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 3 ? 'active' : ''}`} onClick={() => this.showFilterPop(3)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='order-category'
                        active={this.state.showFilterPop === 3}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>supplier</span>
                      <div>
                        <ArrowDownward className='pointer icon' onClick={() => this.handleSort('supplier')} />
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 1 ? 'active' : ''}`} onClick={() => this.showFilterPop(1)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='supplier'
                        active={this.state.showFilterPop === 1}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>amount</TableCell>
                  {this.props.rights.includes('price::entry') && <TableCell>value</TableCell>}
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>color</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 4 ? 'active' : ''}`} onClick={() => this.showFilterPop(4)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='order-color'
                        active={this.state.showFilterPop === 4}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <div className='flex align-center nowrap'>
                        <span className='strong'>FA</span>
                        <Tooltip title='food approved'>
                          <InfoOutlined sx={{width: '17px', marginLeft: '5px'}} />
                        </Tooltip>
                      </div>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 6 ? 'active' : ''}`} onClick={() => this.showFilterPop(6)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='order-foodSafe'
                        active={this.state.showFilterPop === 6}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <span>material</span>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 5 ? 'active' : ''}`} onClick={() => this.showFilterPop(5)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='order-material'
                        active={this.state.showFilterPop === 5}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>delivery date</TableCell>
                  <TableCell>
                    <div className="flex space-between align-center nowrap td-with-icon">
                      <div className='flex align-center nowrap'>
                        <span className='strong'>truck</span>
                      </div>
                      <div>
                        <FilterList className={`pointer icon ${this.state.showFilterPop === 7 ? 'active' : ''}`} onClick={() => this.showFilterPop(7)} />
                      </div>
                      <FilterPop 
                        submitFilters={this.submitFilters}
                        filters={this.state.filters}
                        type='truckPlate'
                        active={this.state.showFilterPop === 7}
                        closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                        updateFilter={this.updateFilter}
                      />
                    </div>
                  </TableCell>
                  <TableCell>storage</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.entries.map((order: {id: number, attributes: OrderData}, i: number) => (
                  <React.Fragment key={order.id}>
                  <TableRow
                    className='double-border'
                    key={'report-'+i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {order.attributes.creator?.data?.attributes.name}
                    </TableCell>
                    <TableCell>
                      {order.attributes.positions && order.attributes.positions.length > 1 && <ExpandMore className={`pointer`} style={this.state.tableExpandedPos.includes(i) ? {transform: 'rotate(270deg)'} : {}} onClick={() => this.expandOrder(i)} />}
                    </TableCell>
                    <TableCell sx={{padding: 0}} align='center'>
                      {order.attributes.isDraft ? <span>&nbsp;</span> : (
                        <>
                          {order.attributes.isCancelled && <Tooltip title='storniert'><div style={{position: 'relative', top: '1px'}} className="swatch red"></div></Tooltip>}
                          {order.attributes.isFulfilled && <Tooltip title='abgeschlossen'><div style={{position: 'relative', top: '1px'}} className="swatch green"></div></Tooltip>}
                          {order.attributes.readOnly && !order.attributes.isFulfilled && <Tooltip title='teilweise angeliefert'><div style={{position: 'relative', top: '1px'}} className="swatch orange"></div></Tooltip>}
                          {!order.attributes.readOnly && !order.attributes.isCancelled && !order.attributes.isFulfilled && <Tooltip title='offen (kann bearbeitet werden)'><div style={{position: 'relative', top: '1px'}} className="swatch gray"></div></Tooltip>}
                        </>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.attributes.isDraft ? (
                        <span className='pointer' onClick={() => {this.setState({modalMode: 'updateDraft'});this.editDraft(order.id)}}>Entwurf</span>
                      ) : (
                        <span>
                          {/* TODO: startEdit function should set modal mode */}
                          {!order.attributes.isCancelled && !order.attributes.isFulfilled && !order.attributes.isDraft && !order.attributes.readOnly && <strong className='pointer' onClick={() => {this.setState({modalMode: 'prepare'}); this.editDraft(order.id)}}>{order.attributes.customId || 'FEHLER'}</strong>}
                          {(order.attributes.isFulfilled || order.attributes.isCancelled) && <strong className='pointer' onClick={() => {this.setState({modalMode: 'view'}); this.editDraft(order.id)}}>{order.attributes.customId || 'FEHLER'}</strong>}
                          {order.attributes.readOnly && !order.attributes.isFulfilled && <strong className='pointer' onClick={() => {this.setState({modalMode: 'prepare-remaining'}); this.editDraft(order.id)}}>{order.attributes.customId || 'FEHLER'}</strong>}
                        </span>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.attributes.positions.length >= 2 ? (
                        order.attributes.positions.length + ' Positionen'
                      ) : (order.attributes.positions[0]?.typeIntern || order.attributes.positions[0]?.typeOffer || <i>n/a</i>)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.attributes.positions.length >= 2 ? (
                        order.attributes.positions.length + ' Positionen'
                      ) : (convertGoodsCategory(order.attributes.positions[0]?.category) || <i>n/a</i>)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.attributes.supplier}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.attributes.positions.length >= 2 ? (
                        order.attributes.positions.length + ' Positionen'
                      ) : `${order.attributes.positions[0]?.quantity ? (order.attributes.positions[0]?.unit === 'kg' ? localNumberInTons(Number(order.attributes.positions[0]?.quantity)) : localNumber(Number(order.attributes.positions[0]?.quantity))) : 'n/a'} ${order.attributes.positions[0]?.unit === 'kg' ? 't' : order.attributes.positions[0]?.unit}` || 'n/a'}
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                      {getWeightOfOrder(order.attributes)} kg
                    </TableCell> */}
                    {this.props.rights.includes('price::entry') && <TableCell component="th" scope="row">
                      {checkRightsPriceOrder(order.attributes, this.props.rights, 'entry') ? `${getTotalOfOrder(order.attributes)} €` : ''}
                    </TableCell>}
                    <TableCell>
                      {order.attributes.positions.length >= 2 ? (
                        order.attributes.positions.length + ' Positionen'
                      ) : convertOrderAttributes(order.attributes.positions[0]?.color || '-')}
                    </TableCell>
                    <TableCell>
                      {order.attributes.positions.length >= 2 ? (
                        order.attributes.positions.length + ' Positionen'
                      ) : convertOrderAttributes(order.attributes.positions[0]?.foodSafe || '-')}
                    </TableCell>
                    <TableCell>
                      {order.attributes.positions.length >= 2 ? (
                        order.attributes.positions.length + ' Positionen'
                      ) : convertOrderAttributes(order.attributes.positions[0]?.material || '-')}
                    </TableCell>
                    <TableCell>
                      {order.attributes.positions.length < 2 && !order.attributes.isCancelled && (
                        <React.Fragment>
                          {order.attributes.positions[0].truckData && order.attributes.positions[0].truckData.length >= 1 && order.attributes.positions[0].truckData.map((td, i) => (
                            <React.Fragment key={`createdAt-${order.id}-${i}`}>
                              {td.fulfilled ? (
                                <React.Fragment><span className='green'>{td.actualDeliveryDate ? convertDateString(td.actualDeliveryDate) : 'FEHLER'}</span><br /></React.Fragment>
                              ) : (
                                <React.Fragment><span>{td.truckDeliveryDate ? <React.Fragment>{convertDateString(td.truckDeliveryDate)}</React.Fragment> : <span className='red'>n/a</span>}</span><br /></React.Fragment>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      )}
                      {!order.attributes.isCancelled && order.attributes.positions[0].trucksCount && order.attributes.positions[0].truckData && [...Array(Number(order.attributes.positions[0].trucksCount) - order.attributes.positions[0].truckData.length)].map(() => <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>)}
                      {!order.attributes.isCancelled && !order.attributes.isDraft && !order.attributes.positions[0].trucksCount && <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>}
                    </TableCell>
                    <TableCell>
                      {order.attributes.positions.length < 2 && (
                        <React.Fragment>
                          {!order.attributes.isCancelled && order.attributes.positions[0].truckData && order.attributes.positions[0].truckData.length >= 1 && order.attributes.positions[0].truckData.map((td, i) => (
                            <React.Fragment><span>{td.truckPlate ? td.truckPlate : <span className='red'>n/a</span>}</span><br /></React.Fragment>
                          ))}
                          {!order.attributes.isCancelled && order.attributes.positions[0].trucksCount && order.attributes.positions[0].truckData && [...Array(Number(order.attributes.positions[0].trucksCount) - order.attributes.positions[0].truckData.length)].map(() => <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>)}
                          {!order.attributes.isCancelled && !order.attributes.isDraft && !order.attributes.positions[0].trucksCount && <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>}
                        </React.Fragment>
                      )}
                    </TableCell>
                    <TableCell>
                      {order.attributes.positions.length < 2 && (
                        <React.Fragment>
                          {!order.attributes.isCancelled && order.attributes.positions[0].truckData && order.attributes.positions[0].truckData.length >= 1 && order.attributes.positions[0].truckData.map((td, i) => (
                            // @ts-ignore
                            <div className='flex align-center'><span>{this.state.warehouseSpots.filter((spot: WarehouseSpot) => spot.id === td.storagePlace).length === 1 ? this.state.warehouseSpots.filter(spot => spot.id === td.storagePlace)[0].attributes.name : <span className='red'>n/a</span>}</span>{!td.fulfilled ? (this.checkStoreCapacity(td) ? null : (<React.Fragment>&nbsp;<Tooltip title='Auf diesem Lagerplatz ist mittlerweile nicht mehr genug Platz.'><Error className='red' sx={{fontSize: '1rem'}} /></Tooltip></React.Fragment>)) : null}<br /></div>
                          ))}
                        </React.Fragment>
                      )}
                      {!order.attributes.isCancelled && order.attributes.positions[0].trucksCount && order.attributes.positions[0].truckData && [...Array(Number(order.attributes.positions[0].trucksCount) - order.attributes.positions[0].truckData.length)].map(() => <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>)}
                      {!order.attributes.isCancelled && !order.attributes.isDraft && !order.attributes.positions[0].trucksCount && <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>}
                    </TableCell>
                    {this.props.user.email === "ang@ksmrecycling.at" ?
                    <TableCell align="right">
                    {<Edit className='warning action' onClick={() => {this.setState({modalMode: 'updateDraft'});this.editDraft(order.id)}} />}
                    {/* {!order.attributes.isCancelled && !order.attributes.isFulfilled && !order.attributes.isDraft && !order.attributes.readOnly && <Edit className='warning action' onClick={() => {this.setState({modalMode: 'prepare'}); this.editDraft(order.id)}} />}
                    {(order.attributes.isFulfilled || order.attributes.isCancelled) && <Edit className='warning action' onClick={() => {this.setState({modalMode: 'updateDraft'});this.editDraft(order.id)}} />}
                    {order.attributes.readOnly && !order.attributes.isFulfilled && <VisibilityOutlined className='action' onClick={() => {this.setState({modalMode: 'prepare-remaining'}); this.editDraft(order.id)}} />} */}
                    &nbsp;&nbsp;{<DeleteSharp className='error action' onClick={() => this.handleIncommingOrderDelete(order.id)} />}
                    </TableCell>
                    :
                    <TableCell align="right">
                    {!order.attributes.isFulfilled && order.attributes.isDraft &&  <Edit className='warning action' onClick={() => {this.setState({modalMode: 'updateDraft'});this.editDraft(order.id)}} />}
                    {!order.attributes.isCancelled && !order.attributes.isFulfilled && !order.attributes.isDraft && !order.attributes.readOnly && <Edit className='warning action' onClick={() => {this.setState({modalMode: 'prepare'}); this.editDraft(order.id)}} />}
                    {(order.attributes.isFulfilled || order.attributes.isCancelled) && <LockOutlined className='action' onClick={() => {this.setState({modalMode: 'view'}); this.editDraft(order.id)}} />}
                    {order.attributes.readOnly && !order.attributes.isFulfilled && <VisibilityOutlined className='action' onClick={() => {this.setState({modalMode: 'prepare-remaining'}); this.editDraft(order.id)}} />}
                  </TableCell>}   
                  </TableRow>
                  {this.state.tableExpandedPos.includes(i) && order.attributes.positions.length >= 2 && order.attributes.positions.map((pos, i) => (
                    <TableRow
                      key={'position-'+order.id+i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {''}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {''}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {''}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {pos.typeIntern || pos.typeOffer || <i>n/a</i>}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {convertGoodsCategory(pos.category) || <i>n/a</i>}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {''}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {pos.unit === 'kg' ? localNumberInTons(pos.quantity ? pos.quantity : 'n/a') : localNumber(pos.quantity ? pos.quantity : 'n/a')} {pos.unit === 'kg' ? 't' : pos.unit}
                      </TableCell>
                      {this.props.rights.includes('price::entry') && <TableCell component="th" scope="row">
                        {checkRightsPrice(pos, this.props.rights, 'entry') ? `${formatter.format(calcTotalOfPos(pos))} €` : ''}
                      </TableCell>}
                      <TableCell>
                        {convertOrderAttributes(pos.color || '-')}
                      </TableCell>
                      <TableCell>
                        {convertOrderAttributes(pos.foodSafe || '-')}
                      </TableCell>
                      <TableCell>
                        {convertOrderAttributes(pos.material || '-')}
                      </TableCell>
                      <TableCell>
                        {!order.attributes.isCancelled && pos.truckData && pos.truckData.length >= 1 && pos.truckData.map((td, i) => (
                          <React.Fragment>
                            {td.fulfilled ? (
                              <React.Fragment><span className='green'>{td.actualDeliveryDate ? convertDateString(td.actualDeliveryDate) : 'FEHLER'}</span><br /></React.Fragment>
                            ) : (
                              <div className='flex align-center'><span>{td.truckDeliveryDate ? <React.Fragment>{convertDateString(td.truckDeliveryDate)}</React.Fragment> : 'n/a'}</span><br /></div>
                            )}
                          </React.Fragment>
                        ))}
                        {!order.attributes.isCancelled && pos.trucksCount && pos.truckData && [...Array(Number(pos.trucksCount) - pos.truckData.length)].map(() => <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>)}
                        {!order.attributes.isCancelled && !order.attributes.isDraft && !pos.trucksCount && <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>}
                      </TableCell>
                      <TableCell>
                        {!order.attributes.isCancelled && pos.truckData && pos.truckData.length >= 1 && pos.truckData.map((td, i) => (
                          <React.Fragment><span>{td.truckPlate ? td.truckPlate : 'n/a'}</span><br /></React.Fragment>
                        ))}
                        {!order.attributes.isCancelled && pos.trucksCount && pos.truckData && [...Array(Number(pos.trucksCount) - pos.truckData.length)].map(() => <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>)}
                        {!order.attributes.isCancelled && !order.attributes.isDraft && !pos.trucksCount && <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>}
                      </TableCell>
                      <TableCell>
                        {!order.attributes.isCancelled && pos.truckData && pos.truckData.length >= 1 && pos.truckData.map((td, i) => (
                          // @ts-ignore
                          <React.Fragment><span>{this.state.warehouseSpots.filter((spot: WarehouseSpot) => spot.id === td.storagePlace).length === 1 ? this.state.warehouseSpots.filter(spot => spot.id === td.storagePlace)[0].attributes.name : 'n/a'}</span><br /></React.Fragment>
                        ))}
                        {!order.attributes.isCancelled && pos.trucksCount && pos.truckData && [...Array(Number(pos.trucksCount) - pos.truckData.length)].map(() => <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>)}
                        {!order.attributes.isCancelled && !order.attributes.isDraft && !pos.trucksCount && <React.Fragment><span className='red'>n/a</span><br /></React.Fragment>}
                      </TableCell>
                      <TableCell align="right">
                        {''}
                      </TableCell>
                    </TableRow>
                  ))}
                  </React.Fragment>
                ))}
                <TableRow className='footer-row'>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Summe</TableCell>
                  <TableCell>{this.getTableTotal().quantity}</TableCell>
                  {this.props.rights.includes('price::entry') && (
                    <TableCell>
                      {
                        this.props.rights.includes('price::entry.r') &&
                        this.props.rights.includes('price::entry.rpet') &&
                        this.props.rights.includes('price::entry.hw') &&
                        this.props.rights.includes('price::entry.zl') &&
                        this.props.rights.includes('price::entry.d') &&
                        this.props.rights.includes('price::entry.bm') &&
                        this.props.rights.includes('price::entry.nm') ?
                        this.getTableTotal().value : ''
                      }
                    </TableCell>
                  )}
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>  : 
          <Paper style={{padding: '20px'}}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="70vh"
                    paddingLeft={0}
                  >
                  <CircularProgress />
                  </Box>
            {/* Keine Bestellungen gefunden. */}
            </Paper>
        }
       
     
            <NewOrderForm
              active={this.state.showNewOrderModal}
              onClose={this.closeNewOrderModal}
              fetchData={this.fetchData}
              modalEditDraftMode={this.state.modalEditDraftMode}
              modalEditDraftData={this.state.modalEditDraftData}
              modalMode={this.state.modalMode}
              warehouseSpots={this.state.warehouseSpots}
              rights={this.props.rights}
              handleIncommingPop={this.handleIncommingPop}
              user={this.props.user}
            />
            <Modal
          open={this.state.showDetailPop && this.state.activeGood !== undefined}
          onClose={this.handleIncommingCloasePop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="detail-pop"
        >
          <>
            <span className='modal-close pointer'
             onClick={this.handleIncommingCloasePop}
             ><Close /></span>
            <Box sx={modalStyle}>
              <span className='modal-close pointer'
               onClick={this.handleIncommingCloasePop}
               ><Close /></span>
              <div className="header-bar">
                <h2>Detailansicht</h2>
                {this.state.activeGood && this.state.activeGood?.attributes.label && this.state.activeGood?.attributes.label.data ? (
                  <div><a href={this.state.activeGood.attributes.label?.data.attributes.url} target='_blank' rel='noreferrer' style={{ color: 'white' }}><Button variant='contained'><QrCode2 /></Button></a></div>
                ) : null}
              </div>
              <h3>Grunddaten</h3>
              <div className="flex of-4 spacing-1">
                <TextField
                  helperText='ID'
                  value={this.state.activeGood?.attributes.customId || 'nicht gefunden'}
                  disabled
                />
                <TextField
                  helperText='Kategorie'
                  value={this.state.activeGood?.attributes.category ? convertGoodsCategory(this.state.activeGood?.attributes.category) : 'nicht gefunden'}
                  disabled
                />
                <div style={{ position: 'relative' }}>
                  <TextField
                    helperText='Bezeichnung'
                    value={this.state.activeGood?.attributes.typeIntern || 'nicht gefunden'}
                    name='typeIntern'
                    disabled={this.state.enableUpdate !== 'typeIntern'}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                    fullWidth
                  />
                  <span className='update-field' 
                  // onClick={() => this.handleUpdate('typeIntern')}
                  >
                    {this.state.enableUpdate === 'typeIntern' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'color' ? (
                    <TextField
                      helperText='Farbe'
                      value={this.state.activeGood?.attributes.color || ''}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      name='color'
                    >
                      {/* {rpetColors.map(value => (
                        <MenuItem key={`color-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))} */}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Farbe'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.color || 'n/a')}
                      disabled
                      fullWidth
                    />
                  )}
                  <span className='update-field'
                  //  onClick={() => this.handleUpdate('color')}
                   >
                    {this.state.enableUpdate === 'color' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'foodSafe' ? (
                    <TextField
                      helperText='LME'
                      value={this.state.activeGood?.attributes.foodSafe || ''}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='foodSafe'
                    >
                      {/* {foodSafeOptions.map(value => (
                        <MenuItem key={`foodSafe-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))} */}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='LME'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.foodSafe || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  <span className='update-field'
                  //  onClick={() => this.handleUpdate('foodSafe')}
                   >
                    {this.state.enableUpdate === 'foodSafe' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'material' ? (
                    <TextField
                      helperText='Material'
                      value={this.state.activeGood?.attributes.material || ''}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='material'
                    >
                      {/* {materials.map(value => (
                        <MenuItem key={`material-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))} */}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Material'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.material || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  <span className='update-field' 
                  // onClick={() => this.handleUpdate('material')}
                  >
                    {this.state.enableUpdate === 'material' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='integriert von'
                  value={this.state.activeGood?.attributes?.creator?.data?.attributes.name || '???'}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
                <TextField
                  helperText='Status'
                  value={translate(this.state.activeGood?.attributes.status || '???')}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
              </div>
              <h3>Lager</h3>
              <div className="flex of-4 spacing-1">
                <div style={{ position: 'relative' }}>
                  <TextField
                    type={this.state.enableUpdate === 'quantity' ? 'number' : 'text'}
                    helperText='Menge'
                    value={this.state.enableUpdate === 'quantity' ? Number(this.state.activeGood?.attributes.quantity || 0) : (localNumber(this.state.activeGood?.attributes.quantity || '') || 'nicht gefunden')}
                    fullWidth
                    name='quantity'
                    disabled={this.state.enableUpdate !== 'quantity'}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{this.state.activeGood?.attributes.unit || ''}</InputAdornment>
                    }}
                  />
                  <span className='update-field'
                  //  onClick={() => this.handleUpdate('quantity')}
                   >
                    {this.state.enableUpdate === 'quantity' ? 'speichern' : 'ändern'}
                  </span>
                </div>
                <TextField
                  helperText='Wert'
                  value={(this.state.activeGood?.attributes.baseValue ? localCurrency((this.state.activeGood?.attributes.baseValue) * this.state.activeGood?.attributes.quantity) : 0)}
                  disabled
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
                <div style={{ position: 'relative' }}>
                  {/* {this.state.enableSpotChange ? (
                    <TextField
                      helperText='Lagerplatz'
                      value={this.state.newSpot || ''}
                      onChange={e => this.setState({ newSpot: e.target.value })}
                      fullWidth
                      select
                    >
                      {this.state.spots && this.state.spots.length >= 1 && this.state.spots.map(spot => (
                        <MenuItem key={`spot-${spot.id}`} value={spot.id}>{spot.attributes.name} - {localNumber(spot.attributes.capacity)} kg frei</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Lagerplatz'
                      value={this.state.activeGood?.attributes.warehouse_spot.data ? this.state.activeGood?.attributes.warehouse_spot.data.attributes.name : 'nicht gefunden'}
                      disabled
                      fullWidth
                    />
                  )}
                  <span className='update-field' onClick={this.handleSpotChange}>
                    {this.state.enableSpotChange ? 'speichern' : 'ändern'}
                  </span> */}
                </div>
                <TextField
                  helperText='erstellt am'
                  value={this.state.activeGood?.attributes.createdAt ? convertDateString(this.state.activeGood?.attributes.createdAt) : 'nicht gefunden'}
                  disabled
                />
              </div>

              <h3>Dokumente</h3>
              <div className="flex of-4 spacing-1 documents">
                <Link to={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch?.data ? `/produktion/chargen?id=${this.state.activeGood.attributes.fromBatch.data.id}` : '#'}>
                  <TextField
                    helperText='ID Charge'
                    value={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch?.data ? this.state.activeGood?.attributes.fromBatch.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch?.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order?.data ? `/wareneingang?id=${this.state.activeGood.attributes.order.data.id}` : '#'}>
                  <TextField
                    helperText='ID Bestellung'
                    value={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order?.data ? this.state.activeGood?.attributes.order.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order?.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders?.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes ? `/warenausgang?id=${this.state.activeGood?.attributes.exit_orders.data[0].id}` : '#'}>
                  <TextField
                    helperText='ID Auftrag'
                    value={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders?.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes && this.state.activeGood?.attributes.exit_orders.data[0].attributes.customId ? this.state.activeGood?.attributes.exit_orders.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders?.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                  <TextField
                    helperText='Laborbericht'
                    value={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports?.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId ? this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports?.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
              </div>
              {
                this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId&&
                <div className='flex of-4 spacing-1 documents'>
                  <Link to={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? `/labor?id=${this.state.activeGood?.attributes.laboratory_reports.data[0].id}` : '#'}>
                    <Button  sx={{ marginTop: '15px' }}className="pt-5" variant='contained'>Go {this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId}</Button>
                  </Link>
                </div>
              }

              <h3>Status</h3>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Aktueller Status</TableCell>
                      <TableCell align='right'>{translate(this.state.activeGood?.attributes.status || 'nicht bekannt')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Geplante Abholung (Warenausgang)</TableCell>
                      {/* <TableCell align='right'>{this.state.activeGood?.id ? this.checkExitDate(this.state.activeGood?.attributes.customId) : '???'}</TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <h3>Status</h3> */}
              {/* //TODO */}
            </Box>
          </>
        </Modal>
      </div>
    )
  }
};

export default withRouter(WarehouseEntry);
