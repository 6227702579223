import { ArrowDownward, CalendarMonth, Close, DeleteSharp, Edit, ExpandMore, FilterList, InfoOutlined, LockOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Tooltip, Paper, Chip, TablePagination, Box, CircularProgress, TextField, Select, OutlinedInput, MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { add, calcTotal, formatter, getTotalOfOrder, multiply } from '../../../services/calculator';
import { fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import { fetchInvetory, fetchSingleInventory, Good, updateGood, updateWarehouseSpot } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { convertDateString, convertOrderAttributes, localCurrency, localNumber, localNumberInTons, toLocalISODate } from '../../../services/stringConverter';
import { checkRightsPriceOrder } from '../../../services/utilitiesService';
import { cancelExitOrder, deleteExitOrder, ExitOrder, ExitOrderAttributes, ExitPos, ExitTruckData, fetchExitOrders, fetchExitOrdersWithFilters, saveOrder, sendPrepareData } from '../../../services/warehouseExitService';
import { NotiStore } from '../../../stores/notifications';
import FilterPop from '../../utils/FilterPop/FilterPop';
import { withRouter } from '../../utils/withRouter/withRouter';
import './WarehouseExit.sass';
import WarehouseExitForm from './WarehouseExitForm/WarehouseExitForm';
import { IncommingSort } from '../../../utils/defaults';

interface WarehouseExitthis {
  searchParams: {[key: string]: string};
  rights: string[];
  user:any

}

export interface WarehouseExitState {
  tab: number;
  orders: ExitOrder[];
  goods: Good[];
  stores: WarehouseSpot[];
  showOrderForm: boolean;
  orderFormMode: string;
  customer: string;
  address: string;
  zip: string;
  cityAndCountry: string;
  contactPerson: string;
  email: string;
  phone: string;
  reference: string;
  category: string;
  positions: ExitPos[];
  expandedPos: number;
  total: string;
  activeOrderIsDraft: boolean;
  activeOrderId: number;
  showFilterPop: number;
  filters: {
    [key: string]: string[];
  };
  activeFilters: boolean;
  sortBy: {
    [key: string]: string;
  };
  tableExpandedPos: number[];
  page:number,
  rowsPerPage:number,
  pagination:{ page: number, pageCount : number ,pageSize : number ,total :  number},
  sort:string,
  search:string
  activeGood: Good | undefined;
  showDetailPop: boolean;
  updateData: { [key: string]: string | number };
  enableUpdate: string;
  enableSpotChange: boolean;
  newSpot: string;
  spots: WarehouseSpot[];
  exitOrders: {
    id: number;
    attributes: ExitOrderAttributes;
  }[];

}

// interface tabStylesIF {
//   width: string;
// }

// const tabStyles: tabStylesIF = {
//   width: '33.333%',
// }

const emptyPos: ExitPos = {
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
  priceUnit: '',
  total: 0,
  color: '',
  foodSafe: '',
  material: '',
  packaging: '',
  trucks: []
}

const emptyTruck: ExitTruckData = {
  plate: '',
  dispatchDate: null,
  startTime:'',
  endTime:'',
  storagePlace: -1,
  goods: [],
  storageTotal: 0,
  fulfilled: false,
  fulfillData: [],
}

const defaultState: WarehouseExitState = {
  tab: 0,
  orders: [],
  stores: [],
  goods: [],
  showOrderForm: false,
  orderFormMode: '',
  customer: '',
  address: '',
  zip: '',
  cityAndCountry: '',
  contactPerson: '',
  email: '',
  phone: '',
  reference: '',
  category: '',
  positions: [{...emptyPos}],
  expandedPos: 1,
  total: '',
  activeOrderIsDraft: false,
  activeOrderId: -1,
  showFilterPop: -1,
  filters: {},
  activeFilters: false,
  sortBy: {},
  tableExpandedPos: [],
  page:0  ,
  rowsPerPage:10,
  pagination:{ page: 1, pageCount : 8 ,pageSize : 5 ,total :  100},
  sort:"select",
  search:'',
  activeGood: undefined,
  showDetailPop: false,
  updateData: {},
  enableUpdate: '',
  enableSpotChange: false,
  newSpot: '',
  spots: [],
  exitOrders: [],

}

class WarehouseExit extends React.Component<WarehouseExitthis, WarehouseExitState> {
  state: WarehouseExitState = {...defaultState}

  componentDidMount() {
    this.setState({positions: [{...emptyPos}]});

    resetNotifications()
    this.fetchData(true)
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
    ) => {
      this.setState({page: this.state.page === 0 ? newPage + 1 : newPage },this.fetchData)
      
    };
    
    handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.setState({page: 0}, this.fetchData)
     
      };

      handleFilter = (type:string,value:string): void => {
      
        NotiStore.setState({ loading: true })
        if(type==='search'){
          this.setState({search:value})
          this.fetchData(false,value,'')
        }else{
            this.fetchData(false,'',value) 
            this.setState({sort:value})
        }
    
      }
    
      clearFilters = (): void => {
        this.setState({ activeFilters: false, filters: {} ,sort:'select',search:'' })
        this.fetchData();
      }
      
  fetchData = (checkSearchParams: boolean = false,search='',sort=''): void => {
    NotiStore.setState({loading: true});

    let orders =  fetchExitOrders(`_q=${search}&pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}`,sort);
    let stores = fetchWarehouseData();
    // TODO: prevent fetching sold goods (will be too much one day)
    let goods = fetchInvetory(`filters[status][0]=stored&filters[status][1]=sold`);
   
    Promise.all([orders, stores, goods])
    .then(res => {
      let tableExpandedPos: number[] = [];

      if (res[0].data && res[0].data.length >= 1) res[0].data.forEach((o, i) => tableExpandedPos.push(i))

      let orders = res[0].data;

      orders.forEach(order => {
        if (order.attributes.documents && order.attributes.documents.data && order.attributes.documents.data.length >= 1) {
          order.attributes.documents.data.sort((a, b) => {
            let aName = a.attributes.name
            let bName = b.attributes.name
            
            let aIndex = Number(aName.match(/-[0-9]_/g)?.toString().replace('-', '').replace('_', '')) || 9999
            let bIndex = Number(bName.match(/-[0-9]_/g)?.toString().replace('-', '').replace('_', '')) || 9999

            if (aIndex > bIndex) return 1
            else if (aIndex < bIndex) return -1
            else return 0
          })
        }
      })

      this.setState({
        orders,
        stores: res[1].spots,
        goods: res[2].data,
        tableExpandedPos,
      }, checkSearchParams ? () => {
        // open order on load
        let {id} = this.props.searchParams;
        if (id) {
          let orders = this.state.orders.filter(order => order.id.toString() === id.toString())

          if (orders.length === 1) {
            let index = this.state.orders.indexOf(orders[0])
            this.startEdit(index, 'view')
          }
        }
        // open order on load end
      } : undefined)

      fetchWarehouseData()
          .then(resWh => {
            if (resWh) this.setState({ spots: resWh.spots || [] })

            fetchExitOrders('filters[isFulfilled]=false')
              .then(res => {
                this.setState({ exitOrders: res.data }, checkSearchParams ? () => {
                  let { id } = this.props.searchParams;

                  if (id) {
                    this.showProductDetail(Number(id))
                  }
                } : undefined);

                NotiStore.setState({ loading: false })
              })
              .catch(err => {
                NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen des Inventars aufgetreten.' })
                NotiStore.setState({ loading: false })
              })
          })
          .catch(err => {
            NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Lagerdaten aufgetreten.' })
            NotiStore.setState({ loading: false })
          })
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({tab: newValue});
    this.clearFilters();
  };

  onPopClose = (): void => {
    this.setState(prevState => ({
      ...defaultState, 
      tab: prevState.tab, 
      orders: prevState.orders,
      goods: prevState.goods,
      stores: prevState.stores,
    }))
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement> | {target: {name: string, value: string}}): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handlePosAccordion = (id: number): void => {
    this.setState({expandedPos: this.state.expandedPos === id ? -1 : id});
  }

  addNewPos = ():void => {
    this.setState(prevState => ({positions: [...prevState.positions, {...emptyPos}], expandedPos: prevState.positions.length + 1}))
  }

  handleDeletePos = (id: number): void => {
    this.state.positions[id] && this.setState(prevState => ({
      positions: prevState.positions.filter((pos, aId) => id !== aId),
      expandedPos: -1
    }));
  }

  handleGood = (pos: number, truck: number, id: string): void => {
    if (typeof this.state.positions[pos] !== 'undefined' && typeof this.state.positions[pos].trucks[truck] !== 'undefined') {
      let {positions} = this.state;
      let {goods} = positions[pos].trucks[truck];

      let error: string = '';

      if (goods.includes(id)) goods.splice(goods.indexOf(id), 1)
      else {
        positions.forEach((position) => position.trucks.forEach(t => t.goods.includes(id) ? error = 'Das Produkt ist bereits in einem anderen LKW verladen.' : null));
        if (!error) goods.push(id);
      };

      positions[pos].trucks[truck].goods = goods;
      positions[pos].trucks[truck].storageTotal = 0;

      if (error) NotiStore.setState({error: true, errorMessage: error})
      else this.setState({positions});
    }
  }

  handlePosChange = (e: React.ChangeEvent<HTMLInputElement> | {target: {name: string, value: string | number}}): void => {
    try {
      console.log(e)
      let {name, value} = e.target;
      let posId: number = Number(name.split('-')[1]);
      let fieldId: string = name.split('-')[0];

      if (posId <= this.state.positions.length - 1) {
        let {positions} = this.state;

        //@ts-ignore
        positions[posId][fieldId] = value;

        let {quantity, price, unit, priceUnit} = this.state.positions[posId];
        if (quantity && price && unit && priceUnit) positions[posId].total = calcTotal(quantity, unit, price, priceUnit);

        let posTotals: number[] = [];
        positions.map(pos => posTotals.push(pos.total))

        this.setState({
          positions,
          total: formatter.format(add(posTotals))
        })
      }
    } catch {}
  }

  submitForm = (isDraft: boolean = false, isUpdate: boolean = false): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    let {customer, address, zip, cityAndCountry, reference, category, contactPerson, email, phone, positions} = this.state;

    let data = {customer, address, zip, cityAndCountry, reference, category, contactPerson, email, phone, positions, isDraft};

    saveOrder(data, isUpdate, this.state.activeOrderId)
    .then(() => {
      this.setState(prevState => ({
        ...defaultState, 
        positions: [{...emptyPos}],
        tab: prevState.tab, 
        orders: prevState.orders,
        goods: prevState.goods,
        stores: prevState.stores,
      }))
      NotiStore.setState({loading: false, success: true, successMessage: this.state.orderFormMode === 'edit' ? 'Auftrag erfolgreich aktualisiert.' : 'Auftrag erfolgreich erstellt.'});
      this.fetchData();
    })
    .catch(err => {
      NotiStore.setState({loading: false, error: true, errorMessage: err.message})
    })
  }

  startEdit = (i: number, mode: string): void => {
    if ((mode === 'edit' || mode === 'prepare' || mode === 'view') && typeof this.state.orders[i] !== 'undefined') {
      let order = this.state.orders[i];

      let {positions} = order.attributes;
      let posTotals: number[] = [];
      positions.map(pos => posTotals.push(pos.total))

      this.setState({
        customer: order.attributes.customer || '',
        address: order.attributes.address || '',
        zip: order.attributes.zip || '',
        cityAndCountry: order.attributes.cityAndCountry || '',
        contactPerson: order.attributes.contactPerson || '',
        email: order.attributes.email || '',
        phone: order.attributes.phone || '',
        reference: order.attributes.reference || '',
        category: order.attributes.category || '',
        positions: order.attributes.positions && order.attributes.positions.length >= 1 ? order.attributes.positions : [{...emptyPos}],
        orderFormMode: mode,
        showOrderForm: true,
        activeOrderIsDraft: order.attributes.isDraft,
        activeOrderId: order.id,
        total: formatter.format(add(posTotals)),
      })
    }
  }

  addTruckToPos = (index: number): void => {
    if (typeof this.state.positions[index] !== 'undefined') {
      let {positions} = this.state;
      let pos = positions[index];

      pos.trucks.push({...emptyTruck});
      positions[index] = pos

      this.setState({positions});
    }
  }

  deleteTruck = (index: number, truck: number): void => {
    if (typeof this.state.positions[index] !== 'undefined' && this.state.positions[index].trucks && typeof this.state.positions[index].trucks[truck] !== 'undefined') {
      let {positions} = this.state;
      let pos = positions[index];

      pos.trucks.splice(truck, 1)
      positions[index] = pos

      this.setState({positions});
    }
  }

  handleTruckChange = (e: React.ChangeEvent<HTMLInputElement>, pos: number, truck: number): void => {
    let {name, value} = e.target;

    name = name.split('-')[0];

    if (name && (pos || pos === 0) && (truck || truck === 0)) {
      if (typeof this.state.positions[pos] !== 'undefined' && this.state.positions[pos].trucks && typeof this.state.positions[pos].trucks[truck] !== 'undefined') {
        let {positions} = this.state;
        let position: ExitPos = positions[pos];

        if (name === 'plate') {
          try {
            position.trucks[truck].plate = value;
          } catch {}
        } else if (name === 'storagePlace') {
          try {
            position.trucks[truck].storagePlace = Number(value);
            position.trucks[truck].goods = [];
          } catch {}
        } else if (name === 'dispatchDate') {
          try {
            position.trucks[truck][name] = new Date(value);
          } catch {}
        } else if (name === 'storageTotal') {
          try {
            position.trucks[truck].storageTotal = Number(value);
            position.trucks[truck].goods = [];
          } catch {}
        }else if (name === 'startTime') {
          try {
            position.trucks[truck][name] = value;
          } catch {}
        }else if (name === 'endTime') {
          try {
            position.trucks[truck][name] = value;
          } catch {}
        }
  
        positions[pos] = position
  
        this.setState({positions});
      }
    }
  }

  calcTruckTotal = (i: number, j: number): number => {
    let total = 0;

    if (typeof this.state.positions[i] !== 'undefined' && typeof this.state.positions[i].trucks[j] !== 'undefined') {
      if (this.state.positions[i].trucks[j].storageTotal) {
        total = this.state.positions[i].trucks[j].storageTotal
      } else {
        let goods: string[] = [];

        goods = goods.concat(this.state.positions[i].trucks[j].goods);

        if (goods.length) goods.forEach(good => {
          let match = this.state.goods.filter(g => g.attributes.customId === good);

          if (match.length === 1) total += match[0].attributes.quantity;
        })
      }
    }

    return total
  }

  calcPosTotal = (i: number): number => {
    let total = 0;

    if (typeof this.state.positions[i] !== 'undefined') {
      let pos = this.state.positions[i];

      let goods: string[] = [];

      pos.trucks.forEach(truck => {
        if (truck.storageTotal) {
          total += truck.storageTotal
        } else {
          goods = goods.concat(truck.goods)
        }
      });

      if (goods.length) goods.forEach(good => {
        let match = this.state.goods.filter(g => g.attributes.customId === good);

        if (match.length === 1) total += match[0].attributes.quantity;
      })
    }

    return total
  }

  sendPrepareData = (): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    let {positions} = this.state;
    let id = this.state.activeOrderId;
    let error: string = '';

    if (!positions || positions.length < 1) {
      error = 'Keine Positionendaten gefunden.'
    } else {
      // positions.forEach((pos, i) => {
      //   if ((pos.quantity - this.calcPosTotal(i)) > 0) error = `Die zugewiesene Menge für Position ${i+1} ist zu niedrig.`
      // })
    }

    if (error) {
      NotiStore.setState({loading: false, error: true, errorMessage: error});
    } else {
      let data: {positions: ExitPos[], id: number} = {
        positions,
        id
      }

      sendPrepareData(data)
      .then(() => {
        
        this.setState(prevState => ({...defaultState, 
          positions: [{...emptyPos}],
          tab: prevState.tab, 
          orders: prevState.orders,
          goods: prevState.goods,
          stores: prevState.stores,
        }))

        NotiStore.setState({loading: false, success: true, successMessage: 'Auftrag erfolgreich aktualisiert.'});
        this.fetchData();
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message})
      })
    }
  }



  cancelExitOrder = (id: number): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    if (!id || id < 0) NotiStore.setState({loading: false, error: true, errorMessage: 'Bestelldaten nicht gefunden.'})
    else {
      let question = `Bist du sicher, dass du diese Bestellung ${this.state.activeOrderIsDraft ? 'löschen' : 'stornieren'} möchtest?`
      if (window.confirm(question)) {
        cancelExitOrder(id)
        .then(() => {
          NotiStore.setState({loading: false, success: true, successMessage: `Bestellung erfolgreich ${this.state.activeOrderIsDraft ? 'gelöscht' : 'storniert'}.`})

          this.setState(prevState => ({...defaultState, 
            positions: [{...emptyPos}],
            tab: prevState.tab, 
            orders: prevState.orders,
            goods: prevState.goods,
            stores: prevState.stores,
          }))
  
          this.onPopClose();
          this.fetchData();
        })
        .catch(err => {
          NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Aktualisieren der Bestellung.'})
        })
      }
    }
  }

  showFilterPop = (id: number): void => {
    if ((id && id > 0) || id === 0) {
      let {showFilterPop} = this.state;

      if (showFilterPop !== id) showFilterPop = id
      else {
        showFilterPop = defaultState.showFilterPop
      }

      this.setState({showFilterPop})
    }
  }

  submitFilters = (): void => {
    NotiStore.setState({loading: true})

    let {filters, sortBy} = this.state

    this.setState({activeFilters: true})

    fetchExitOrdersWithFilters(filters, sortBy)
    .then(res => {
      this.setState({orders: res.data || []});
      NotiStore.setState({loading: false})
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: err.message || 'Fehler beim Abrufen der Aufträge aufgetreten.', loading: false})
    })
  }

  // clearFilters = (): void => {
  //   this.setState({activeFilters: false, filters: {}})

  //   this.fetchData();
  // }

  updateFilter = (e: React.ChangeEvent<HTMLInputElement>, key: string, multiple: string = ''): void => {
    let {filters} = this.state;

    if (multiple === 'multiple') {
      if (!filters[key]) filters[key] = [e.target.value]
      else {
        if (!filters[key].includes(e.target.value)) filters[key].push(e.target.value)
        else {
          let index = filters[key].indexOf(e.target.value)
          if (index > -1) filters[key].splice(index, 1)
        }
      }
    } else {
      if (e.target.value) filters[key] = [e.target.value]
      else delete filters[key]
    }

    this.setState({filters})
  }

  handleSort = (value: string): void => {
    if (value) {
      NotiStore.setState({loading: true})

      let {sortBy} = this.state
      if (sortBy.id === value) {
        if (sortBy.direction === 'desc') this.setState({sortBy: {id: value, direction: 'asc'}}, this.submitFilters)
        else if (sortBy.direction === 'asc') this.setState({sortBy: {id: value, direction: 'desc'}}, this.submitFilters)
      } else {
        this.setState({sortBy: {id: value, direction: 'asc'}}, this.submitFilters)
      }

      NotiStore.setState({loading: false})
    }
  }

  getTableTotal = (): {quantity: string, value: string} => {
    let data = {
      quantity: 'n/a',
      value: 'n/a',
    }

    let quantity: number = 0
    let value: number = 0

    if (this.state.orders.length >= 1) this.state.orders.forEach((order) => {
      if (!order.attributes.isDraft && !order.attributes.isCancelled && order.attributes.positions && order.attributes.positions.length >= 1) order.attributes.positions.forEach(pos => {
        if (pos.quantity && pos.unit === 'kg') quantity += Number(pos.quantity)
        else if (pos.quantity && pos.unit === 't') quantity += (Number(pos.quantity)*1000)

        if (pos.total) value += pos.total
      })
    })

    data.quantity = `${localNumber(quantity / 1000)} t`
    data.value = `${localCurrency(value)} €`

    return data
  }

  expandOrder = (i: number): void => {
    if (i >= 0) {
      let {tableExpandedPos} = this.state

      if (tableExpandedPos.includes(i)) {
        let index = tableExpandedPos.indexOf(i)

        if (index > -1) {
          tableExpandedPos.splice(index, 1)
        }
      } else {
        tableExpandedPos.push(i)
      }

      this.setState({tableExpandedPos})
    }
  }
  showProductDetail = (id: any): void => {

    resetNotifications()
    NotiStore.setState({ loading: true })

    fetchSingleInventory(id)
      .then(res => {
        this.setState({
          activeGood: res.data,
          showDetailPop: true
        })
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler beim Abrufen der Produktdaten.' })
      })
      .finally(() => NotiStore.setState({ loading: false }))
  }

  onCloseDetailPop = (): void => {
    this.setState({
      activeGood: defaultState.activeGood,
      showDetailPop: false,
      updateData: defaultState.updateData,
      enableUpdate: defaultState.enableUpdate,
    })
  }

  handleUpdate = (key: string): void => {
    let { enableUpdate, updateData } = this.state

    if (!enableUpdate && key) {
      this.setState({ enableUpdate: key })
    } else {
      resetNotifications()
      NotiStore.setState({ loading: true })

      updateGood(updateData)
        .then(res => {
          NotiStore.setState({ loading: false, success: true, successMessage: res.message || 'Produkt erfolgreich aktualisiert' })

          this.fetchData()
          if (this.state.activeGood) this.showProductDetail(this.state.activeGood.id)
        })
        .catch(err => {
          NotiStore.setState({ loading: false, error: true, errorMessage: err.message || 'Fehler' })
        })
        .finally(() => {
          this.setState({ enableUpdate: defaultState.enableUpdate })
        })
    }
  }

  handleSpotChange = (): void => {
    if (this.state.enableSpotChange) {
      resetNotifications()
      NotiStore.setState({ loading: true })

      updateWarehouseSpot(this.state.activeGood ? this.state.activeGood.id : 0, this.state.newSpot)
        .then(res => {
          NotiStore.setState({ loading: false, success: true, successMessage: res.message || 'Produkt erfolgreich aktualisiert' })

          this.fetchData()
          if (this.state.activeGood) this.showProductDetail(this.state.activeGood.id)
        })
        .catch(err => {
          NotiStore.setState({ loading: false, error: true, errorMessage: err.message || 'Fehler' })
        })
    }

    this.setState({ enableSpotChange: !this.state.enableSpotChange })
  }
  checkExitDate = (id: string): string => {
    let { exitOrders } = this.state;
    let date: string = ''

    if (exitOrders.length >= 1) {
      exitOrders.forEach(order => {
        let { positions } = order.attributes

        if (positions && positions.length >= 1) positions.forEach(pos => {
          if (pos.trucks && pos.trucks.length >= 1) pos.trucks.forEach(truck => {
            if (truck.goods.includes(id)) {
              if (truck.dispatchDate) date = truck.dispatchDate.toString()
              else date = 'n/a'
            }
          })
        })
      })
    }

    return date ? convertDateString(date) : 'nicht geplant'
  }

  async handleOutgoingOrderDelete(orderId:number){
    if (window.confirm('Diesen ausgehenden Auftrag löschen?')) {
      const response = await deleteExitOrder(orderId)
      if (response.success) {
        NotiStore.setState({ loading: false, success: true, successMessage: 'Erfolgreich.' })

        this.fetchData()
      }
    } 
  }
  render() {
    return (
      <div className="WarehouseExit content" data-testid="WarehouseExit">
        <div className='header-bar'>
          <h1>
            Outgoing
            <Link to={'/warenausgang/kalender'} style={{marginLeft: 10}}><Button variant='contained' color='secondary'><CalendarMonth /></Button></Link>
          </h1>
          <Button variant="contained" onClick={() => this.setState({showOrderForm: true, orderFormMode: 'create'})}>
          Bestellung erstellen
            {/* Auftrag erstellen */}
          </Button>
        </div>

        {/* <div style={{marginBottom: 30}}>
          <Box>
            <Tabs
              value={this.state.tab}
              onChange={this.handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile={false}
            >
              <Tab label="Aufträge" sx={tabStyles} />
              <Tab label="Betriebsmittel" sx={tabStyles} />
              <Tab label="Divers" sx={tabStyles} />
            </Tabs>
          </Box>
        </div> */}

        {this.state.activeFilters ? (
          <Chip color='error' className='pointer' sx={{marginBottom: '15px'}} label={<span className='flex align-center'><Close sx={{fontSize: '14px', marginRight: '10px'}} />Filter zurücksetzen</span>} onClick={this.clearFilters} />
        ) : null}
         <div style={{ marginTop: 10,marginBottom:20 }}>
            <TextField value={this.state.search} placeholder='Search...' onChange={(e)=>this.handleFilter('search',e.target.value)} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            label="Sort"
            value={this.state.sort}
            input={<OutlinedInput />}
            onChange={(e) => this.handleFilter('sort',e.target.value)}
            // onClose={()=>this.handleFilter('sort','')}
            sx={{ width: '300px' }}
          >
           
            {IncommingSort.map((status,i) => (
              <MenuItem key={i} value={status.value}>
                {status.Label}
              </MenuItem>
            ))}

          </Select>&nbsp;&nbsp;
          {/* <Button onClick={()=>this.handleFilter('sort','')} variant='contained'>Sort</Button>&nbsp;&nbsp; */}
          <Button onClick={()=>this.clearFilters()} variant='contained' color="error">Clear</Button>
          </div>
          {
          this.state.orders.length >= 1 && 
          <TablePagination
            component={"div"}
            count={this.state.pagination.total}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        }
        {this.state.tab === 0 && (
          <div className='ExitOrdersTable'>
            {this.state.orders.length >= 1 ? (
              <TableContainer className='orders-table' component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;Order Creator Name</TableCell>
                      <TableCell>Delivery Cost</TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <div className='flex align-center nowrap'>
                            <span className='strong'></span>
                          </div>
                          <div>
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 7 ? 'active' : ''}`} onClick={() => this.showFilterPop(7)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='orderStatus'
                            active={this.state.showFilterPop === 7}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <span>ID</span>
                          <div>
                            <ArrowDownward className='pointer icon' onClick={() => this.handleSort('customId')} />
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 0 ? 'active' : ''}`} onClick={() => this.showFilterPop(0)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='customId'
                            active={this.state.showFilterPop === 0}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>Erstellt am</TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <span>Kunde</span>
                          <div>
                            <ArrowDownward className='pointer icon' onClick={() => this.handleSort('customer')} />
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 1 ? 'active' : ''}`} onClick={() => this.showFilterPop(1)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='customer'
                            active={this.state.showFilterPop === 1}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <span>Bezeichnung</span>
                          <div>
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 5 ? 'active' : ''}`} onClick={() => this.showFilterPop(5)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='typeIntern'
                            active={this.state.showFilterPop === 5}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <span>Farbe</span>
                          <div>
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 2 ? 'active' : ''}`} onClick={() => this.showFilterPop(2)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='order-color'
                            active={this.state.showFilterPop === 2}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <div className='flex align-center nowrap'>
                            <span className='strong'>LME</span>
                            <Tooltip title='lebensmittelecht'>
                              <InfoOutlined sx={{width: '17px', marginLeft: '5px'}} />
                            </Tooltip>
                          </div>
                          <div>
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 3 ? 'active' : ''}`} onClick={() => this.showFilterPop(3)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='order-foodSafe'
                            active={this.state.showFilterPop === 3}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex space-between align-center nowrap td-with-icon">
                          <span>Material</span>
                          <div>
                            <FilterList className={`pointer icon ${this.state.showFilterPop === 4 ? 'active' : ''}`} onClick={() => this.showFilterPop(4)} />
                          </div>
                          <FilterPop 
                            submitFilters={this.submitFilters}
                            filters={this.state.filters}
                            type='order-material'
                            active={this.state.showFilterPop === 4}
                            closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                            updateFilter={this.updateFilter}
                          />
                        </div>
                      </TableCell>
                      <TableCell>Abholdatum</TableCell>
                    <TableCell>
                      <div className="flex space-between align-center nowrap td-with-icon">
                        <div className='flex align-center nowrap'>
                          <span className='strong'>LKW Nr.</span>
                        </div>
                        <div>
                          <FilterList className={`pointer icon ${this.state.showFilterPop === 6 ? 'active' : ''}`} onClick={() => this.showFilterPop(6)} />
                        </div>
                        <FilterPop 
                          submitFilters={this.submitFilters}
                          filters={this.state.filters}
                          type='truckPlate'
                          active={this.state.showFilterPop === 6}
                          closePop={() => this.setState({showFilterPop: defaultState.showFilterPop})}
                          updateFilter={this.updateFilter}
                        />
                      </div>
                    </TableCell>
                      {this.props.rights.includes('price::exit') && <TableCell>Wert</TableCell>}
                      <TableCell>Dokumente</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.orders.length >= 1 && this.state.orders.map((order: ExitOrder, i) => (
                    <>
                      <TableRow key={`order-${i}`}>
                        <TableCell>
                          {order.attributes.positions && order.attributes.positions.length > 1 && <ExpandMore className={`pointer`} style={this.state.tableExpandedPos.includes(i) ? {transform: 'rotate(270deg)'} : {}} onClick={() => this.expandOrder(i)} />}
                        </TableCell>
                        <TableCell>
                          {order?.attributes?.creator?.data?.attributes.name}
                        </TableCell>
                        <TableCell>
                          {order?.attributes?.deliveryCost ? order?.attributes?.deliveryCost :'-'}
                        </TableCell>
                        <TableCell>
                          {order.attributes.isCancelled && <Tooltip title='storniert'><div style={{position: 'relative', top: '1px'}} className="swatch red"></div></Tooltip>}
                          {order.attributes.isFulfilled && <Tooltip title='abgeschlossen'><div style={{position: 'relative', top: '1px'}} className="swatch green"></div></Tooltip>}
                          {order.attributes.readOnly && !order.attributes.isFulfilled && <Tooltip title='teilweise angeliefert'><div style={{position: 'relative', top: '1px'}} className="swatch orange"></div></Tooltip>}
                          {!order.attributes.readOnly && !order.attributes.isCancelled && !order.attributes.isFulfilled && <Tooltip title='offen (kann bearbeitet werden)'><div style={{position: 'relative', top: '1px'}} className="swatch gray"></div></Tooltip>}
                        </TableCell>
                        <TableCell>{order.attributes.isDraft ? (
                          <span onClick={() => this.startEdit(i, 'edit')} className='pointer'>Entwurf</span>
                        ) : (
                          <span className='pointer'>
                            {!order.attributes.isDraft && !order.attributes.isFulfilled && !order.attributes.readOnly && !order.attributes.isCancelled && <strong onClick={() => this.startEdit(i, 'prepare')}>{order.attributes.customId}</strong>}
                            {(order.attributes.isFulfilled || order.attributes.isCancelled) && <strong onClick={() => this.startEdit(i, 'view')}>{order.attributes.customId}</strong>}
                            {order.attributes.readOnly && !order.attributes.isFulfilled && !order.attributes.isCancelled && <strong onClick={() => this.startEdit(i, 'view')}>{order.attributes.customId}</strong>}
                          </span>
                        )}</TableCell>
                        <TableCell>{convertDateString(order.attributes.createdAt)}</TableCell>
                        <TableCell>{order.attributes.customer || 'n/a'}</TableCell>
                        <TableCell>{order.attributes.positions.length >= 1 ? (
                          <>
                            {order.attributes.positions.length >= 2 ? `${order.attributes.positions.length} Positionen` : order.attributes.positions.map((pos, j) => (
                              <React.Fragment key={'pos-name-' + i + j}><span>{pos.name}</span><br /></React.Fragment>
                            ))}
                          </>
                        ) : 'n/a'}</TableCell>
                        <TableCell>{order.attributes.positions.length >= 1 ? (
                          <>
                            {order.attributes.positions.length >= 2 ? `${order.attributes.positions.length} Positionen` : order.attributes.positions.map((pos, j) => (
                              <React.Fragment key={'pos-quantity-' + i + j}><span>{pos.quantity && pos.unit ? (pos.unit === 'kg' ? localNumberInTons(pos.quantity) : localNumber(pos.quantity)) + ' ' + (pos.unit === 'kg' ? 't' : pos.unit) : 'n/a'}</span><br /></React.Fragment>
                            ))}
                          </>
                        ) : 'n/a'}</TableCell>
                        <TableCell>{order.attributes.positions.length >= 1 ? (
                          <>
                            {order.attributes.positions.length >= 2 ? `${order.attributes.positions.length} Positionen` : order.attributes.positions.map((pos, j) => (
                              <React.Fragment key={'pos-color-' + i + j}><span>{pos.color ? convertOrderAttributes(pos.color) : 'n/a'}</span><br /></React.Fragment>
                            ))}
                          </>
                        ) : 'n/a'}</TableCell>
                        <TableCell>{order.attributes.positions.length >= 1 ? (
                          <>
                            {order.attributes.positions.length >= 2 ? `${order.attributes.positions.length} Positionen` : order.attributes.positions.map((pos, j) => (
                              <React.Fragment key={'pos-foodsafe-' + i + j}><span>{pos.foodSafe ? convertOrderAttributes(pos.foodSafe) : 'n/a'}</span><br /></React.Fragment>
                            ))}
                          </>
                        ) : 'n/a'}</TableCell>
                        <TableCell>{order.attributes.positions.length >= 1 ? (
                          <>
                            {order.attributes.positions.length >= 2 ? `${order.attributes.positions.length} Positionen` : order.attributes.positions.map((pos, j) => (
                              <React.Fragment key={'pos-material-' + i + j}><span>{pos.material ? convertOrderAttributes(pos.material) : 'n/a'}</span><br /></React.Fragment>
                            ))}
                          </>
                        ) : 'n/a'}</TableCell>
                        <TableCell>{order.attributes.positions.length === 1 ? order.attributes.positions.map((pos, i) => !pos.trucks || pos.trucks.length < 1 ? <span className='red'>n/a<br /></span> : pos.trucks.map((truck, j) => (
                          <React.Fragment key={'dispatch'+i+j}>{truck.dispatchDate ? <span>{convertDateString(toLocalISODate(truck.dispatchDate))}</span> : <span className='red'>n/a</span>}<br /></React.Fragment>
                        ))) : ''}</TableCell>
                        <TableCell>{order.attributes.positions.length === 1 ? order.attributes.positions.map((pos, i) => !pos.trucks || pos.trucks.length < 1 ? <span className='red'>n/a<br /></span> : pos.trucks.map((truck, j) => (
                          <React.Fragment key={'plate'+i+j}>{truck.plate ? truck.plate : <span className='red'>n/a</span>}<br /></React.Fragment>
                        ))) : ''}</TableCell>
                        {this.props.rights.includes('price::exit') && <TableCell>
                          {checkRightsPriceOrder(order.attributes, this.props.rights, 'exit') ? `${getTotalOfOrder(order.attributes)} €` : ''}
                        </TableCell>}
                        {order.attributes.positions.length === 1 ? (
                          <>
                            <TableCell>
                                {order.attributes.documents && order.attributes.documents.data && order.attributes.documents.data.length >= 1 ? (
                                  <div>
                                    {order.attributes.documents.data.map((doc, i) => (
                                      <>
                                        {doc.attributes.name.includes('lieferschein') && (
                                          <a style={{color: '#222'}} href={doc.attributes.url} target='_blank' rel='noreferrer' key={doc.attributes.name}><Tooltip title={doc.attributes.name}><div className='letter-icon'>LS</div></Tooltip></a>
                                        )}
                                        {doc.attributes.name.includes('packliste') && (
                                          <a style={{color: '#222'}} href={doc.attributes.url} target='_blank' rel='noreferrer' key={doc.attributes.name}><Tooltip title={doc.attributes.name}><div className='letter-icon'>PL</div></Tooltip></a>
                                        )}
                                      </>
                                    ))}
                                  </div>
                                ) : ''}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell></TableCell>
                          </>
                        )}
                        {this.props.user.email === "ang@ksmrecycling.at" ? 
                            <TableCell>
                          {<Edit className='warning action' onClick={() => this.startEdit(i, 'edit')} />}
                          {/* {!order.attributes.isDraft && !order.attributes.isFulfilled && !order.attributes.readOnly && !order.attributes.isCancelled && <Edit className='warning action' onClick={() => this.startEdit(i, 'prepare')} />}
                          {(order.attributes.isFulfilled || order.attributes.isCancelled) && <LockOutlined className='action' onClick={() => this.startEdit(i, 'view')} />} */}
                          {/* {order.attributes.readOnly && !order.attributes.isFulfilled && !order.attributes.isCancelled && <VisibilityOutlined className='action' onClick={() => this.startEdit(i, 'view')} />}   */}
                          &nbsp;&nbsp;<DeleteSharp className='error action' onClick={() => this.handleOutgoingOrderDelete(order.id)} />
                        </TableCell>:
                        <TableCell>
                        {order.attributes.isDraft && <Edit className='warning action' onClick={() => this.startEdit(i, 'edit')} />}
                        {!order.attributes.isDraft && !order.attributes.isFulfilled && !order.attributes.readOnly && !order.attributes.isCancelled && <Edit className='warning action' onClick={() => this.startEdit(i, 'prepare')} />}
                        {(order.attributes.isFulfilled || order.attributes.isCancelled) && <LockOutlined className='action' onClick={() => this.startEdit(i, 'view')} />}
                        {order.attributes.readOnly && !order.attributes.isFulfilled && !order.attributes.isCancelled && <VisibilityOutlined className='action' onClick={() => this.startEdit(i, 'view')} />}  
                      </TableCell>}
                      </TableRow>
                      {this.state.tableExpandedPos.includes(i) && order.attributes.positions.length >= 2 ? order.attributes.positions.map((pos, i) => (
                        <TableRow key={`pos-${order.id}-${i}`}>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>{pos.name}</TableCell>
                          <TableCell>{(pos.unit === 'kg' ? localNumberInTons(pos.quantity) : localNumber(pos.quantity))} {(pos.unit === 'kg' ? 't' : pos.unit)}</TableCell>
                          <TableCell>{convertOrderAttributes(pos.color || 'n/a')}</TableCell>
                          <TableCell>{convertOrderAttributes(pos.foodSafe || 'n/a')}</TableCell>
                          <TableCell>{convertOrderAttributes(pos.material || 'n/a')}</TableCell>
                          <TableCell>
                            {!pos.trucks || pos.trucks.length < 1 ? <span className='red'>n/a<br /></span> : pos.trucks.map((truck, j) => (
                              <React.Fragment key={'dispatch'+i+j}>{truck.dispatchDate ? <span>{convertDateString(toLocalISODate(truck.dispatchDate))}</span> : <span className='red'>n/a</span>}<br /></React.Fragment>
                            ))}
                          </TableCell>
                          <TableCell>
                            {!pos.trucks || pos.trucks.length < 1 ? <span className='red'>n/a<br /></span> : pos.trucks.map((truck, j) => (
                              <React.Fragment key={'plate'+i+j}>{truck.plate ? truck.plate : <span className='red'>n/a</span>}<br /></React.Fragment>
                            ))}
                          </TableCell>
                          {this.props.rights.includes('price::exit') && <TableCell>{pos.quantity && pos.price ? (localCurrency(multiply(pos.quantity, pos.price)) + ' €') : 'n/a'}</TableCell>}
                          <TableCell>
                            {!pos.trucks || pos.trucks.length < 1 ? <span className='red'>n/a<br /></span> : pos.trucks.map((truck, j) => (
                              <>
                                {order.attributes.documents && order.attributes.documents.data && order.attributes.documents.data.length >= 1 ? order.attributes.documents.data.map((doc) => (
                                  <>
                                    {truck.plate && doc.attributes.name.includes('lieferschein') && doc.attributes.name.includes(truck.plate) && (
                                      <a style={{color: '#222'}} href={doc.attributes.url} target='_blank' rel='noreferrer' key={doc.attributes.name}><Tooltip title={doc.attributes.name}><div className='letter-icon'>LS</div></Tooltip></a>
                                    )}
                                    {truck.plate && doc.attributes.name.includes('packliste') && doc.attributes.name.includes(truck.plate) && (
                                      <a style={{color: '#222'}} href={doc.attributes.url} target='_blank' rel='noreferrer' key={doc.attributes.name}><Tooltip title={doc.attributes.name}><div className='letter-icon'>PL</div></Tooltip></a>
                                    )}
                                  </>
                                )) : ''}
                              </>
                            ))}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )) : null}
                    </>
                    ))}
                    <TableRow className='footer-row'>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Summe</TableCell>
                      <TableCell>{this.getTableTotal().quantity}</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      {this.props.rights.includes('price::exit') && (
                        <TableCell>
                          {
                            this.props.rights.includes('price::exit.r') &&
                            this.props.rights.includes('price::exit.rpet') &&
                            this.props.rights.includes('price::exit.hw') &&
                            this.props.rights.includes('price::exit.zl') &&
                            this.props.rights.includes('price::exit.d') &&
                            this.props.rights.includes('price::exit.bm') &&
                            this.props.rights.includes('price::exit.nm') ?
                            this.getTableTotal().value : ''
                          }
                        </TableCell>
                      )}
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <Paper style={{padding: '20px'}}><Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="70vh"
            paddingLeft={0}
          >
          <CircularProgress />
          </Box></Paper>}
          </div>
        )}
        
          {
           

            <WarehouseExitForm
              active={this.state.showOrderForm}
              onClose={this.onPopClose}
              handleChange={this.handleChange}
              addNewPos={this.addNewPos}
              handleDeletePos={this.handleDeletePos}
              handlePosAccordion={this.handlePosAccordion}
              handlePosChange={this.handlePosChange}
              addTruckToPos={this.addTruckToPos}
              deleteTruck={this.deleteTruck}
              handleTruckChange={this.handleTruckChange}
              handleGood={this.handleGood}
              calcTruckTotal={this.calcTruckTotal}
              calcPosTotal={this.calcPosTotal}
              submitForm={this.submitForm}
              sendPrepareData={this.sendPrepareData}
              cancelExitOrder={this.cancelExitOrder}
              popMode={this.state.orderFormMode}
              state={this.state}
              rights={this.props.rights}
              showProductDetail={this.showProductDetail}
              onCloseDetailPop={this.onCloseDetailPop}
              handleUpdate={this.handleUpdate}
              handleSpotChange={this.handleSpotChange}
              checkExitDate={this.checkExitDate}
            />
          }
      </div>
    )
  }
}

export default withRouter(WarehouseExit);
