import { Close } from '@mui/icons-material';
import { Box, Button, MenuItem, Modal, TextField } from '@mui/material';
import React from 'react';
import { Batch, fetchBatches } from '../../../../services/batchesService';
import { fetchWarehouseData, WarehouseSpot } from '../../../../services/configService';
import { createProduct, CreateProductData } from '../../../../services/inventoryService';
import { resetNotifications } from '../../../../services/notiStoreService';
import { convertOrderAttributes, localNumber, translate } from '../../../../services/stringConverter';
import { DeliveredPos, fetchOrders, OrderData } from '../../../../services/warehouseEntriesService';
import { NotiStore } from '../../../../stores/notifications';
import { foodSafeOptions, materials, packagings, rpetColors, units } from '../../../../utils/defaults';
import './ProductForm.sass';

interface ProductFormProps {
  active: boolean;
  onClose: () => void;
  fetchData: () => void;
}
interface ProductFormState {
  category: string;
  typeIntern: string;
  color: string;
  foodSafe: string;
  material: string;
  packaging: string;
  quantity: number;
  unit: string;
  baseValue: number;
  totalValue: number;
  entryDate: string;
  warehouse_spot: number;
  batch: number;
  order: number;
  truck: string;
  dPos: string;
  stores: WarehouseSpot[];
  orders: {id: number; attributes: OrderData}[];
  batches: Batch[];
}

const defaultState: ProductFormState = {
  category: '',
  typeIntern: '',
  color: '',
  foodSafe: '',
  material: '',
  packaging: '',
  quantity: 0,
  unit: '',
  baseValue: 0,
  totalValue: 0,
  entryDate: '',
  warehouse_spot: 0,
  batch: 0,
  order: 0,
  truck: '',
  dPos: '',
  stores: [],
  orders: [],
  batches: [],
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

class ProductForm extends React.Component<ProductFormProps, ProductFormState> {
  state = {...defaultState}

  componentDidMount(): void {
    resetNotifications()
    this.fetchData()
  }

  fetchData = (): void => {
    NotiStore.setState({loading: true});

    let stores = fetchWarehouseData();
    let orders = fetchOrders('filters[readOnly]=true');
    let batches = fetchBatches('filters[status][0]=active&filters[status][1]=finished');

    Promise.all([stores, orders, batches])
    .then(res => {

      this.setState({
        stores: res[0].spots || [],
        orders: res[1].data || [],
        batches: res[2].data || [],
      })
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: 'Fehler beim Abrufen der Daten aufgetreten.'})
    })
    .finally(() => {
      NotiStore.setState({loading: false})
    })
  }

  onClose = () => {
    this.props.onClose()

    this.setState(prevState => ({
      ...defaultState,
      stores: prevState.stores,
      orders: prevState.orders,
      batches: prevState.batches,
    }))
  }

  calculateValue = () => {
    let {quantity, unit, baseValue} = this.state

    if (quantity && unit && baseValue) {
      this.setState({totalValue: quantity * baseValue})
    }
  }

  onCreate = (): void => {
    resetNotifications()
    NotiStore.setState({loading: true})

    let { category, typeIntern, color, foodSafe, material, packaging, quantity, unit, baseValue, entryDate, warehouse_spot, batch, order, truck, dPos } = this.state

    let data: CreateProductData = { category, typeIntern, color, foodSafe, material, packaging, quantity, unit, baseValue, entryDate, warehouse_spot, batch, order, truck, dPos }

    createProduct(data)
    .then(res => {
      NotiStore.setState({success: true, successMessage: res.message})
      this.onClose();
      this.props.fetchData();
    })
    .catch(err => {
      NotiStore.setState({error: true, errorMessage: err.message})
    })
    .finally(() => NotiStore.setState({loading: false}))
  }

  getDeliveredPositions = (): DeliveredPos[] => {
    let positions: DeliveredPos[] = [];

    if (this.state.order && this.state.truck && this.state.orders.filter(order => order.id === this.state.order).length === 1) {
      let order = this.state.orders.filter(order => order.id === this.state.order)[0]
      if (order.attributes.positions && order.attributes.positions.length >= 1) order.attributes.positions.forEach(pos => {
        if (pos.truckData && pos.truckData.length >= 1) {
          let truck = pos.truckData[Number(this.state.truck.split('_')[1])]
          if (truck && truck.deliveredPositions && truck.deliveredPositions.length >= 1) positions = positions.concat(truck.deliveredPositions)
        }
      })
    }

    return positions
  }

  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.onClose}
        className="ProductForm"
      >
        <Box sx={modalStyle}>
          <span className='modal-close pointer' onClick={this.onClose}><Close /></span>
          <h2>Neues Produkt erstellen</h2>
          <div className="flex of-4 spacing-1">
            <TextField
              error={this.state.category ? false :true}
              value={this.state.category || ''}
              onChange={(e) => this.setState({category: e.target.value})}
              helperText='Kategorie'
              select
            >
              <MenuItem value='rpet'>rPET</MenuItem>
              <MenuItem value='r'>Rohstoff</MenuItem>
              {/* <MenuItem value='hw'>Handelsware</MenuItem>
              <MenuItem value='zl'>Handelsware (ZL)</MenuItem> */}
              <MenuItem value='bm'>Betriebsmittel</MenuItem>
              <MenuItem value='d'>Nebenstrom Sortierung</MenuItem>
              <MenuItem value='cw'>Chargenwechsel</MenuItem>
              <MenuItem value='nm'>Nebenstrom</MenuItem>
            </TextField>
            <TextField
              value={this.state.typeIntern || ''}
              error={this.state.typeIntern ? false :true}
              onChange={(e) => this.setState({typeIntern: e.target.value})}
              helperText='Bezeichnung intern'
            />
            {this.state.category !== 'bm' && (
              <>
                <TextField
                  value={this.state.color || ''}
                  error={this.state.color ? false :true}
                  onChange={(e) => this.setState({color: e.target.value})}
                  helperText='Farbe'
                  select
                >
                  {rpetColors.map(color => (
                    <MenuItem key={color} value={color}>{convertOrderAttributes(color)}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  error={this.state.foodSafe ? false :true}
                  value={this.state.foodSafe || ''}
                  onChange={(e) => this.setState({foodSafe: e.target.value})}
                  helperText='Lebensmittelecht'
                  select
                >
                  {foodSafeOptions.map(option => (
                    <MenuItem key={option} value={option}>{convertOrderAttributes(option)}</MenuItem>
                  ))}
                </TextField>
                <TextField
                  error={this.state.material ? false :true}
                  value={this.state.material || ''}
                  onChange={(e) => this.setState({material: e.target.value})}
                  helperText='Material'
                  select
                >
                  {materials.map(option => (
                    <MenuItem key={option} value={option}>{convertOrderAttributes(option)}</MenuItem>
                  ))}
                </TextField>
              </>
            )}
            <TextField
              value={this.state.packaging || ''}
              error={this.state.packaging ? false :true}
              onChange={(e) => this.setState({packaging: e.target.value})}
              helperText='Verpackung'
              select
            >
              {packagings.map(option => (
                <MenuItem key={option} value={option}>{convertOrderAttributes(option)}</MenuItem>
              ))}
            </TextField>
            <TextField
              value={this.state.warehouse_spot || ''}
              error={this.state.warehouse_spot ? false :true}
              onChange={(e) => this.setState({warehouse_spot: Number(e.target.value)})}
              helperText='Lager'
              type='number'
              select
            >
              {this.state.stores.map(store => (
                <MenuItem key={`store-${store.id}`} value={store.id}>{store.attributes.name} - {localNumber(store.attributes.capacity)} kg frei</MenuItem>
              ))}
            </TextField>
            <div className='input-with-unit'>
              <TextField
                error={this.state.quantity ? false :true}
                value={this.state.quantity || ''}
                onChange={(e) => this.setState({quantity: Number(e.target.value)}, this.calculateValue)}
                helperText='Menge'
                type='number'
              />
              <TextField
                error={this.state.unit ? false :true}
                value={this.state.unit || ''}
                onChange={(e) => this.setState({unit: e.target.value}, this.calculateValue)}
                select
              >
                {units.map(option => (
                  <MenuItem key={option} value={option}>{translate(option)}</MenuItem>
                ))}
              </TextField>
            </div>
            {/* <TextField
              value={(this.state.category === 'rpet' || this.state.category === 'cw' || this.state.category === 'nm' || Number(this.state.dPos) >= 0) ? 'wird berechnet' : this.state.baseValue || ''}
              onChange={(e) => this.setState({baseValue: Number(e.target.value)}, this.calculateValue)}
              helperText='Wert'
              type={(this.state.category === 'rpet' || this.state.category === 'cw' || this.state.category === 'nm' || Number(this.state.dPos) >= 0) ? 'text' : 'number'}
              InputProps={(this.state.category === 'rpet' || this.state.category === 'cw' || this.state.category === 'nm') ? {} : {
                endAdornment: <InputAdornment position="end">€ / {translate(this.state.unit || 'kg')}</InputAdornment>
              }}
              disabled={(this.state.category === 'rpet' || this.state.category === 'cw' || this.state.category === 'nm' || Number(this.state.dPos) >= 0)}
            />
            <div></div>
            {this.state.category !== 'bm' && (
              <>
                <div></div>
              </>
            )}
            <TextField
              disabled
              helperText='Gesamtwert'
              value={(this.state.category === 'rpet' || this.state.category === 'cw' || this.state.category === 'nm' || Number(this.state.dPos) >= 0) ? 'wird berechnet' : `${localNumber(this.state.totalValue)} €`}
            /> */}
          </div>

          <div style={{marginTop: 30}}>
            {(this.state.category === 'rpet' || this.state.category === 'cw' || this.state.category === 'nm') && (
              <>
                <h3 style={{marginBottom: 0}}>Produktionsdaten</h3>
                <div className="flex of-2 spacing-1">
                  <TextField
                    error={this.state.batch ? false :true}
                    value={this.state.batch || ''}
                    onChange={(e) => this.setState({batch: Number(e.target.value)})}
                    helperText='Charge'
                    type='number'
                    select
                  >
                    {this.state.batches.map(batch => (
                      <MenuItem key={`batch-${batch.id}`} value={batch.id}>{batch.attributes.customId} ({batch.attributes.type})</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={this.state.entryDate ? false :true}
                    value={this.state.entryDate || ''}
                    onChange={(e) => this.setState({entryDate: e.target.value})}
                    helperText='Eingang'
                    type='datetime-local'
                  />
                </div>
              </>
            )}
            {(this.state.category === 'd') && (
              <>
                <h3 style={{marginBottom: 0}}>Produktionsdaten</h3>
                <div className="flex of-2 spacing-1">
                  <TextField
                    error={this.state.batch ? false :true}
                    value={this.state.batch || ''}
                    onChange={(e) => this.setState({batch: Number(e.target.value)})}
                    helperText='Charge'
                    type='number'
                    select
                  >
                    {this.state.batches.map(batch => (
                      <MenuItem key={`batch-${batch.id}`} value={batch.id}>{batch.attributes.customId} ({batch.attributes.type})</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={this.state.entryDate ? false :true}
                    value={this.state.entryDate || ''}
                    onChange={(e) => this.setState({entryDate: e.target.value})}
                    helperText='Eingang'
                    type='datetime-local'
                  />
                </div>
              </>
            )}
            {(this.state.category === 'r' || this.state.category === 'bm' || this.state.category === 'hw' || this.state.category === 'zl') && (
              <>
                <h3 style={{marginBottom: 0}}>Bestelldaten</h3>
                <div className="flex of-4 spacing-1">
                  <TextField
                    error={this.state.order ? false :true}
                    value={this.state.order || ''}
                    onChange={(e) => this.setState({order: Number(e.target.value), truck: defaultState.truck})}
                    helperText='Bestellung'
                    type='number'
                    select
                  >
                    {this.state.orders.map(order => (
                      <MenuItem key={`order-${order.id}`} value={order.id}>{order.attributes.customId}</MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    error={this.state.truck ? false :true}
                    value={this.state.truck || ''}
                    onChange={(e) => this.setState({truck: e.target.value})}
                    helperText='LKW'
                    select
                  >
                    {this.state.orders.filter(order => order.id === this.state.order).length === 1 && this.state.orders.filter(order => order.id === this.state.order)[0].attributes.positions.map((pos, i) => pos.truckData && pos.truckData.length >= 1 && pos.truckData.map((truck, j) => (
                      <MenuItem key={`truck-${truck.truckPlate}`} value={`${i}_${j}`}>P{i+1} ({pos.typeIntern}): {truck.truckPlate}</MenuItem>
                    )))}
                  </TextField>
                  <TextField
                    error={this.state.dPos ? false :true}
                    value={this.state.dPos}
                    onChange={(e) => this.setState({dPos: e.target.value})}
                    helperText='Position'
                    select
                  >
                    {this.getDeliveredPositions().map((pos: DeliveredPos, j: number) => (
                      <MenuItem key={`dPos-${j}`} value={j.toString()}>P{j+1}: {pos.type}</MenuItem>
                    ))}
                    <MenuItem value={'-1'}>Neue Position erstellen</MenuItem>
                  </TextField>
                  <TextField
                    error={this.state.entryDate ? false :true}
                    value={this.state.entryDate || ''}
                    onChange={(e) => this.setState({entryDate: e.target.value})}
                    helperText='Eingang'
                    type='datetime-local'
                  />
                </div>
              </>
            )}
          </div>

          <div className='text-right' style={{marginTop: 30}}>
            <Button variant='contained' onClick={this.onCreate}>Produkt anlegen</Button>
          </div>
        </Box>
      </Modal>
    )
  }
};

export default ProductForm;
