import { Close, ExpandMoreOutlined } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Modal, Box, TextField, MenuItem, InputAdornment, Button, TableHead, TableCell, Table, TableContainer, Paper, TableBody, TableRow } from '@mui/material';
import React from 'react';
import { add, calcTotal, calcTotalOfPos, formatter } from '../../../services/calculator';
import { supplierOptions, OrderData, saveOrder, OrderPosition, cancelOrder, updateTotals, TruckData, saveRestoreOrder } from '../../../services/warehouseEntriesService';
import './NewOrderForm.sass';
import { NotiStore } from '../../../stores/notifications';
import { resetNotifications } from '../../../services/notiStoreService';
import HeadingArrow from '../../utils/HeadingArrow/HeadingArrow';
import { WarehouseSpot } from '../../../services/configService';
import { convertDateString, convertGoodsCategory, convertOrderAttributes, localNumber, localPriceInTons, translate } from '../../../services/stringConverter';
import { rpetColors } from '../../../utils/defaults';
import { Link } from 'react-router-dom';
import { Batch } from '../../../services/batchesService';
import { checkRightsPrice, checkRightsPriceOrder } from '../../../services/utilitiesService';

interface NewOrderFormProps {
  active: boolean;
  onClose: () => void;
  fetchData: () => void;
  modalEditDraftMode: boolean;
  modalEditDraftData: {
    id: number;
    attributes: OrderData;
  } | null;
  modalMode: string;
  warehouseSpots: WarehouseSpot[];
  rights: string[];
  handleIncommingPop:(good:any) => void;
  user:any
}

interface NewOrderFormState {
  expandedPos: number;
  total: string;
  isFulfilled: boolean;
  customId: string;
  supplier: string;
  address: string;
  zip: string;
  cityAndCountry: string;
  contactPerson: string;
  email: string;
  phone: string;
  reference: string;
  positions: OrderPosition[];
  batches: Batch[];
  enableTotals: boolean;
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

const emptyPos = {
  typeOffer: '',
  typeIntern: undefined,
  category: '',
  quantity: 0,
  unit: '',
  price: 0,
  priceUnit: '',
  total: 0,
  color: '',
  foodSafe: '',
  material: '',
  trucksCount: undefined,
  plannedEntryAmount: undefined,
  packaging: undefined,
  truckData: [],
}

const defaultState = {
  customId: '',
  supplier: '',
  address: '',
  zip: '',
  cityAndCountry: '',
  contactPerson: '',
  email: '',
  phone: '',
  reference: '',
  positions: [{...emptyPos}],
  expandedPos: 1,
  total: '0',
  isFulfilled: false,
  batches: [],
  enableTotals: false,
}

const unitOptions = [
  {
    value: 'kg',
    label: 'kg'
  },
  {
    value: 't',
    label: 't'
  },
  {
    value: 'stk',
    label: 'Stk.'
  },
  {
    value: 'l',
    label: 'l'
  },
]

class NewOrderForm extends React.Component<NewOrderFormProps, NewOrderFormState> {
  state = {
    ...defaultState
  }

  componentDidMount(): void {
    this.setState({positions: [{...emptyPos}]})
  }

  componentDidUpdate(prevProps: NewOrderFormProps) {
    if (!prevProps.modalEditDraftMode && this.props.modalEditDraftMode) {
      if (this.props.modalEditDraftData) {
        let posTotals: number[] = [];
        this.props.modalEditDraftData.attributes.positions.map(pos => posTotals.push(Number(calcTotalOfPos(pos))))

        let positions = this.props.modalEditDraftData?.attributes.positions || defaultState.positions;
        
        if (positions.length >= 1) positions.forEach(pos => {
          if (pos.quantity && !pos.plannedEntryAmount) pos.plannedEntryAmount = pos.quantity
          if (pos.typeOffer && !pos.typeIntern) pos.typeIntern = pos.typeOffer
        })

        let batches: Batch[] = [];

        if (this.props.modalEditDraftData.attributes.goods && this.props.modalEditDraftData.attributes.goods.data && this.props.modalEditDraftData.attributes.goods.data.length >= 1) this.props.modalEditDraftData.attributes.goods.data.forEach(good => {
          if (good.attributes.batches && good.attributes.batches.data) good.attributes.batches.data.forEach(batch => {
            if (!batches.includes(batch)) batches.push(batch)
          })
        })

        this.setState({
          customId: this.props.modalEditDraftData.attributes.customId || defaultState.customId,
          supplier: this.props.modalEditDraftData?.attributes.supplier || defaultState.supplier,
          address: this.props.modalEditDraftData?.attributes.address || defaultState.address,
          zip: this.props.modalEditDraftData?.attributes.zip || defaultState.zip,
          cityAndCountry: this.props.modalEditDraftData?.attributes.cityAndCountry || defaultState.cityAndCountry,
          contactPerson: this.props.modalEditDraftData?.attributes.contactPerson || defaultState.contactPerson,
          email: this.props.modalEditDraftData?.attributes.email || defaultState.email,
          phone: this.props.modalEditDraftData?.attributes.phone || defaultState.phone,
          reference: this.props.modalEditDraftData?.attributes.reference || defaultState.reference,
          positions,
          batches,
          total: formatter.format(add(posTotals))
        })
      }
    } else if (prevProps.modalEditDraftMode && !this.props.modalEditDraftMode) {
      this.setState(defaultState)
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let key: string = e.target.name;
    if (Object.keys(this.state).includes(key)) this.setState({[key]: e.target.value} as any);
  }

  handlePosAccordion = (id: number): void => {
    this.setState({expandedPos: this.state.expandedPos === id ? -1 : id});
  }

  addNewPos = ():void => {
    this.setState(prevState => ({positions: [...prevState.positions, {...emptyPos}], expandedPos: prevState.positions.length + 1}))
  }

  handleDeletePos = (id: number): void => {
    this.state.positions[id] && this.setState(prevState => ({
      positions: prevState.positions.filter((pos, aId) => id !== aId),
      expandedPos: -1
    }));
  }

  handlePosChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let {name, value} = e.target;
      let posId: number = Number(name.split('-')[1]);
      let fieldId: string = name.split('-')[0];

      if (fieldId === 'trucksCount' && parseInt(value) > 50) {
        value = '50'
        NotiStore.setState({info: 'Maximal 50 LKWs pro Position möglich.'})
      }

      if (posId <= this.state.positions.length - 1) {
        let {positions} = this.state;

        //@ts-ignore
        positions[posId][fieldId] = value;

        let {quantity, price, unit, priceUnit} = this.state.positions[posId];
        if (quantity && price && unit && priceUnit) positions[posId].total = calcTotal(quantity, unit, price, priceUnit);

        let posTotals: number[] = [];
        positions.map(pos => posTotals.push(pos.total))

        if (fieldId === 'trucksCount' && parseInt(value) < positions[posId].truckData.length) positions[posId].truckData.length = parseInt(value);

        this.setState({
          positions,
          total: formatter.format(add(posTotals))
        })
      }
    } catch {}
  }

  handleTruckDataChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      let {name, value} = e.target;
      let posId: number = Number(name.split('-')[1]);
      let truckId: number = Number(name.split('-')[2]);
      let fieldName: string = name.split('-')[0];

      if (posId <= this.state.positions.length - 1) {
        let {positions} = this.state;
        let {truckData} = positions[posId];

        if (truckData && truckData[truckId]) {
          //@ts-ignore
          truckData[truckId][fieldName] = value
        } else if (truckData && Array.isArray(truckData)) {
          //@ts-ignore
          truckData.push({[fieldName]: value})
        } else {
          //@ts-ignore
          truckData = [{[fieldName]: value}]
        }

        positions[posId].truckData = truckData;

        this.setState({positions})
      }
    } catch {}
  }

  handleRestorOrder = () =>{
    resetNotifications();
    NotiStore.setState({loading: true});
      saveRestoreOrder(this.props.modalEditDraftData?.id,this?.props?.user?.email)
      .then(res => {
        NotiStore.setState({success: true, successMessage: 'Bestellung erfolgreich gespeichert.'})
        this.props.onClose();
        this.props.fetchData();
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Beim Speichern der Bestellung ist ein Fehler aufgetreten.'})
      })
  }

  handleSaveOrder = (asDraft: boolean = false, isUpdate: boolean = false, reportId: number = -1):void => {
    resetNotifications();
    NotiStore.setState({loading: true});
    let error: string | undefined = undefined;

    let {positions} = this.state;

    positions.forEach((pos, i) => {
      if (!asDraft && !(pos.typeOffer && pos.quantity && pos.unit && pos.price && pos.priceUnit)) {
        error = 'Bitte fülle alle Felder aus.'
      }
    })

    let { supplier, address, zip, cityAndCountry, contactPerson, email, phone, reference } = this.state;
    if (!asDraft && !(supplier && address && zip && cityAndCountry && contactPerson && email && phone && reference)) error = 'Bitte fülle alle Felder aus.';

    if (error) {NotiStore.setState({error: true, errorMessage: error, loading: false})}
    else {
      let data: OrderData = {
        ...this.state,
        isDraft: asDraft,
      }

      saveOrder(data, isUpdate, reportId)
      .then(res => {
        NotiStore.setState({success: true, successMessage: 'Bestellung erfolgreich gespeichert.'})

        this.setState({
          ...defaultState,
          positions: [{...emptyPos}],
        });

        this.props.onClose();
        this.props.fetchData();
      })
      .catch(err => {
        NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Beim Speichern der Bestellung ist ein Fehler aufgetreten.'})
      })
    }
  }

  cancelOrder = (id: number): void => {
    resetNotifications();
    NotiStore.setState({loading: true});

    if (!id || id < 0) NotiStore.setState({loading: false, error: true, errorMessage: 'Bestelldaten nicht gefunden.'})
    else {
      let question = `Bist du sicher, dass du diese Bestellung ${this.props.modalEditDraftData?.attributes.isDraft ? 'löschen' : 'stornieren'} möchtest?`
      if (window.confirm(question)) {
        cancelOrder(id)
        .then(() => {
          NotiStore.setState({loading: false, success: true, successMessage: `Bestellung erfolgreich ${this.props.modalEditDraftData?.attributes.isDraft ? 'gelöscht' : 'storniert'}.`})

          this.setState({
            ...defaultState,
            positions: [{...emptyPos}],
          });
  
          this.props.onClose();
          this.props.fetchData();
        })
        .catch(err => {
          NotiStore.setState({loading: false, error: true, errorMessage: err.message ? err.message : 'Fehler beim Aktualisieren der Bestellung.'})
        })
      }
    }
  }

  getTruckData = (pos: OrderPosition, i: number): {deliveredAmount: number, notes: string} => {
    let data = {
      deliveredAmount: 0,
      notes: ''
    }

    if (pos.truckData && typeof pos.truckData[i] !== 'undefined') {
      let truck = pos.truckData[i]
      if (truck.deliveredPositions) truck.deliveredPositions.forEach(dPos => {
        if (dPos.quantity && dPos.unit) data.deliveredAmount += dPos.unit === 't' ? (dPos.quantity * 1000) : dPos.quantity
      })

      if (truck.notes) data.notes = truck.notes
    }

    return data
  }

  getPosData = (pos: OrderPosition): {deliveredAmount: number, difference: number} => {
    let data = {
      deliveredAmount: 0,
      difference: 0
    }

    if (pos.plannedEntryAmount && pos.unit) data.difference += pos.unit === 't' ? (Number(pos.plannedEntryAmount) * 1000) : Number(pos.plannedEntryAmount)

    if (pos.truckData && pos.truckData.length >= 1) pos.truckData.forEach(truck => {
      if (truck.deliveredPositions && truck.deliveredPositions.length >= 1) truck.deliveredPositions.forEach(dPos => {
        if (dPos.quantity && dPos.unit) {
          data.deliveredAmount += dPos.unit === 't' ? (dPos.quantity * 1000) : dPos.quantity
          data.difference -= dPos.unit === 't' ? (dPos.quantity * 1000) : dPos.quantity
        }
      })
    })

    if (data.difference < 0) data.difference = data.difference * -1

    return data
  }

  updateTotals = (): void => {
    if (!this.state.enableTotals) {
      this.setState({enableTotals: true})
    } else {
      NotiStore.setState({loading: true})

      // @ts-ignore
      let positions: {
        total: number,
        truckData: TruckData | undefined
      }[] = this.state.positions.map(pos => {return {
        total: pos.total,
        truckData: pos.truckData ? pos.truckData : undefined
      }})

      updateTotals(this.props.modalEditDraftData && this.props.modalEditDraftData.id ? this.props.modalEditDraftData.id : -1, positions)
      .then(res => {
        if (res.data && res.data.length === this.state.positions.length) {
          let {positions} = this.state

          res.data.forEach((pos, i) => {
            positions[i].total = Number(pos.total)
            positions[i].price = Number(pos.price)
          })

          this.setState({positions}, this.calcOrderTotal)
        }
      })
      .catch(err => {
        NotiStore.setState({error: true, errorMessage: err.message || 'Fehler'})
      })
      .finally(() => {
        this.setState({enableTotals: false})
        NotiStore.setState({loading: false})
      })
    }
  }

  onChangeTotal = (index: number, value: string): void => {
    let {positions} = this.state
    let valueNum = Number(value)

    if (typeof positions[index] !== 'undefined' && !isNaN(valueNum)) {
      positions[index].total = valueNum
      this.setState({positions})
    }
  }

  calcOrderTotal = (): void => {
    let {positions} = this.state
    let posTotals: number[] = [];

    positions.map(pos => posTotals.push(pos.total))

    this.setState({total: formatter.format(add(posTotals))})
  }


  render() {
    return (
      <Modal
        open={this.props.active}
        onClose={this.props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="NewOrderForm"
      >
        <>
          <span className='modal-close pointer' onClick={this.props.onClose}><Close /></span>
          <Box sx={modalStyle}>
            <div className='modal-close-btn' onClick={this.props.onClose}><Close /></div>
            <h2>{this.props.modalMode !== 'create' && this.state.customId ? `Bestellung ${this.state.customId}` : 'Neue Bestellung'}</h2>
            <h3>Lieferant</h3>
            <div className='flex form-fields'>
              {supplierOptions.map((item:any, i) => (
                <React.Fragment key={item.id}>
                  {i === 4 && <div className='form-field'></div>}
                  <div className={'form-field ' + item.id} key={"form-field-" + i}>
                    <div className="input">
                    <TextField
                      
                      name={`${item.id}`}
                      type={item.id === 'email' ? 'email' : 'text'}
                      helperText={item.title}
                      //@ts-ignore
                      value={this.state[item.id]}
                      fullWidth
                      onChange={this.handleChange}
                      disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false : this.props.modalMode === 'view'}
                      size='small'
                    />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <hr />
            <h3>Bestellung</h3>
            <div className="form-fields">
              <div className={'form-field reference'}>
                <div className="input">
                  <TextField
                    name={`reference`}
                    type='text'
                    helperText={'Referenz'}
                    //@ts-ignore
                    value={this.state.reference}
                    error={this.state.reference ? false :true}
                    fullWidth
                    onChange={this.handleChange}
                    disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false : this.props.modalMode === 'view'}
                    size='small'
                  />
                </div>
              </div>
            </div>
            <hr />
            <h3>Positionen</h3>
            <div className='positions'>
              {this.state.positions.map((pos, i) => (
                <div className="position" key={'pos'+i}>
                  <Accordion key={'pos-'+i} expanded={this.state.expandedPos === i + 1}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined className='pointer' onClick={() => this.handlePosAccordion(i + 1)} />}
                      style={{cursor: 'default'}}
                    >
                      <strong>Position {i + 1}</strong>
                      {/* @ts-ignore */}
                      {this.state.positions.length > 1 && this.props.modalMode !== 'view' && !this.state.positions[i].readOnly  && <u style={{marginLeft: 20, cursor: 'pointer'}} onClick={() => this.handleDeletePos(i)}>löschen</u>}
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <div className={`flex spacing-1 form ${this.state.positions[i].category === 'bm' ? 'of-2' : 'of-5'}`}>
                          <div>
                            {/* @ts-ignore */}
                            {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                              <TextField
                                type='text'
                                helperText={'Kategorie'}
                                error={convertGoodsCategory(this.state.positions[i].category) ? false :true}
                                value={convertGoodsCategory(this.state.positions[i].category)}
                                fullWidth
                                size='small'
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false : true}
                              />
                            ) : (
                              <TextField
                                name={`category-${i}`}
                                type='text'
                                helperText={'Kategorie'}
                                error={this.state.positions[i].category ? false :true}
                                value={this.state.positions[i].category || ''}
                                fullWidth
                                onChange={this.handlePosChange}
                                size='small'
                                select
                              >
                                <MenuItem value='bm'>Betriebsmittel</MenuItem>
                                <MenuItem value='entry'>Rohstoff</MenuItem>
                                <MenuItem value='zl'>Zwischenlager</MenuItem>
                                <MenuItem value='hw'>Handel</MenuItem>
                                <MenuItem value='Verarbeitung'>Verarbeitung</MenuItem>
                              </TextField>
                            )}
                          </div>
                          <div>
                            {/* @ts-ignore */}
                            {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                              <TextField
                                type='text'
                                helperText={'Bezeichnung lt. AN'}
                                error={this.state.positions[i].typeOffer ? false :true}
                                value={this.state.positions[i].typeOffer}
                                fullWidth
                                size='small'
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                              />
                            ) : (
                              <TextField
                                name={`typeOffer-${i}`}
                                type='text'
                                helperText={'Bezeichnung laut Angebot'}
                                //@ts-ignore
                                error={this.state.positions[i].typeOffer ? false :true}
                                value={this.state.positions[i].typeOffer}
                                fullWidth
                                onChange={this.handlePosChange}
                                size='small'
                              />
                            )}
                          </div>
                          {this.state.positions[i].category === 'bm' ? null : (
                            <div>
                              {/* @ts-ignore */}
                              {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                                <TextField
                                  type='text'
                                  helperText={'Farbe'}
                                  error={convertOrderAttributes(this.state.positions[i].color) ? false :true}
                                  value={convertOrderAttributes(this.state.positions[i].color)}
                                  fullWidth
                                  size='small'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              ) : (
                                <TextField
                                  select
                                  name={`color-${i}`}
                                  //@ts-ignore
                                  value={this.state.positions[i].color}
                                  error={this.state.positions[i].color ? false :true}
                                  fullWidth
                                  onChange={this.handlePosChange}
                                  helperText='Farbe'
                                  size='small'
                                >
                                  {rpetColors.map(color => <MenuItem key={color + i} value={color}>{convertOrderAttributes(color)}</MenuItem>)}
                                </TextField>
                              )}
                            </div>
                          )}
                          {this.state.positions[i].category === 'bm' ? null : (
                            <div>
                              {/* @ts-ignore */}
                              {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                                <TextField
                                  type='text'
                                  helperText={'Lebensmittelecht?'}
                                  error={convertOrderAttributes(this.state.positions[i].foodSafe) ? false :true}
                                  value={convertOrderAttributes(this.state.positions[i].foodSafe)}
                                  fullWidth
                                  size='small'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              ) : (
                                <TextField
                                  select
                                  name={`foodSafe-${i}`}
                                  //@ts-ignore
                                  error={this.state.positions[i].foodSafe ? false :true}
                                  value={this.state.positions[i].foodSafe}
                                  fullWidth
                                  onChange={this.handlePosChange}
                                  helperText='Lebensmittelecht?'
                                  size='small'
                                >
                                  <MenuItem key={'yes' + i} value='yes'>
                                    Ja
                                  </MenuItem>
                                  <MenuItem key={'no' + i} value='no'>
                                    Nein
                                  </MenuItem>
                                </TextField>
                              )}
                            </div>
                          )}
                          {this.state.positions[i].category === 'bm' ? null : (
                            <div>
                              {/* @ts-ignore */}
                              {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                                <TextField
                                  type='text'
                                  helperText={'Material'}
                                  error={convertOrderAttributes(this.state.positions[i].material || 'n/a') ? false :true}
                                  value={convertOrderAttributes(this.state.positions[i].material || 'n/a')}
                                  fullWidth
                                  size='small'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              ) : (
                                <TextField
                                  select
                                  name={`material-${i}`}
                                  //@ts-ignore
                                  error={this.state.positions[i].material ? false :true}
                                  value={this.state.positions[i].material}
                                  fullWidth
                                  onChange={this.handlePosChange}
                                  helperText='Material'
                                  size='small'
                                >
                                  <MenuItem key={'tray' + i} value='tray'>
                                    Tray
                                  </MenuItem>
                                  <MenuItem key={'bottle' + i} value='bottle'>
                                    Bottle
                                  </MenuItem>
                                  <MenuItem key={'bottleAndTray' + i} value='bottleAndTray'>
                                    Bottle + Tray
                                  </MenuItem>
                                </TextField>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="flex of-3 spacing-1 form" style={{marginTop: 15}}>
                          {/* @ts-ignore */}
                          {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                            <TextField
                              type='text'
                              helperText={'Menge'}
                              error={localNumber(this.state.positions[i].quantity) ? false :true}
                              value={localNumber(this.state.positions[i].quantity) + ' ' + this.state.positions[i].unit}
                              fullWidth
                              size='small'
                              disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                            />
                          ) : (
                            <div className='input-with-unit'>
                              <TextField
                                name={`quantity-${i}`}
                                type='number'
                                helperText={'Menge'}
                                //@ts-ignore
                                error={this.state.positions[i].quantity ? false :true}
                                value={this.state.positions[i].quantity || ''}
                                fullWidth
                                onChange={this.handlePosChange}
                                size='small'
                              />
                              <TextField
                                select
                                name={`unit-${i}`}
                                type='text'
                                //@ts-ignore
                                value={this.state.positions[i].unit}
                                error={this.state.positions[i].unit ? false :true}
                                fullWidth
                                onChange={this.handlePosChange}
                                size='small'
                              >
                                {unitOptions.map((option, j) => (
                                  <MenuItem key={option.value + j} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          )}
                          {this.props.rights.includes('price::entry') && checkRightsPrice(this.state.positions[i], this.props.rights, 'entry') && (
                            <>
                              {/* @ts-ignore */}
                              {this.props.modalMode === 'view' || this.state.positions[i].readOnly ? (
                                <TextField
                                  type='text'
                                  helperText={'Preis'}
                                  error={this.state.positions[i].priceUnit ? false :true}
                                  value={(this.state.positions[i].priceUnit === 'kg' ? localPriceInTons(this.state.positions[i].price) : localNumber(this.state.positions[i].price)) + ' €/' + (this.state.positions[i].priceUnit === 'kg' ? 't' : this.state.positions[i].priceUnit)}
                                  fullWidth
                                  size='small'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              ) : (
                                <div className='input-with-unit'>
                                  <TextField
                                    name={`price-${i}`}
                                    type='number'
                                    helperText={'Preis €/Einheit'}
                                    //@ts-ignore
                                    value={this.state.positions[i].price || ''}
                                    fullWidth
                                    onChange={this.handlePosChange}
                                    size='small'
                                  />
                                  <TextField
                                    select
                                    name={`priceUnit-${i}`}
                                    type='text'
                                    //@ts-ignore
                                    error={this.state.positions[i].priceUnit ? false :true}
                                    value={this.state.positions[i].priceUnit}
                                    fullWidth
                                    onChange={this.handlePosChange}
                                    size='small'
                                  >
                                    {unitOptions.map((option, j) => (
                                      <MenuItem key={option.value + j} value={option.value}>
                                        €/{option.label}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </div>
                              )}
                              <div style={{position: 'relative'}}>
                                <TextField
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false : !(this.props.rights.includes('entry::price-update') && this.state.enableTotals)}
                                  name={`total-${i}`}
                                  type={(this.props.rights.includes('entry::price-update') && this.state.enableTotals) ? 'number' : 'text'}
                                  helperText={'Summe netto'}
                                  //@ts-ignore
                                  error={this.props.rights.includes('entry::price-update') ? false :true}
                                  value={(this.props.rights.includes('entry::price-update') && this.state.enableTotals) ? this.state.positions[i].total : (formatter.format(this.state.positions[i].total) || '')}
                                  fullWidth
                                  onChange={(this.props.rights.includes('entry::price-update') && this.state.enableTotals) ? (e) => this.onChangeTotal(i, e.target.value) : undefined}
                                  size='small'
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                  }}
                                />
                                {/* @ts-ignore */}
                                {(this.props.modalMode === 'view' || this.state.positions[i].readOnly) && this.props.rights.includes('entry::price-update') && (
                                  <span className='update-price' onClick={this.updateTotals}>
                                    {this.state.enableTotals ? 'speichern' : 'ändern'}
                                  </span>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
              <div className="flex of-2 spacing-1">
                <div onClick={this.addNewPos}>
                  {this.props.modalMode !== 'view' && this.props.modalMode !== 'prepare-remaining' && <strong style={{cursor: 'pointer', marginTop: 20, display: 'block'}}><span className='plus-btn'>+</span>weitere Position hinzufügen</strong>}
                </div>
                {this.props.rights.includes('price::entry') && checkRightsPriceOrder(this.props.modalEditDraftData?.attributes || undefined, this.props.rights, 'entry') && (
                  <div className='total-container text-right'>
                    <TextField
                      disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                      name={`total`}
                      type='text'
                      helperText={'Gesamt netto'}
                      error={this.state.total ? false :true}
                      value={this.state.total}
                      size='small'
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                      }}
                    />
                  </div>
                )}
              </div>
              {/* (this.props.modalMode === 'prepare' || this.props.modalMode === 'prepare-remaining' || this.props.modalMode === 'view') && */}
              { (
                <div>
                  <hr />
                  <h3>Lieferung</h3>
                  <HeadingArrow text="System Grunddaten" />
                  <div>
                    {Array.isArray(this.state.positions) && this.state.positions.length >= 1 && this.state.positions.map((good,i) => (
                      <div key={'delivery-' + i} style={{marginTop: 30}}>
                        <div style={{marginBottom: 10}}><strong>Position {i+1}: {good.typeOffer ? good.typeOffer : 'n/a'}</strong></div>
                        <div className='flex of-4 spacing-1'>
                          <div>
                            <TextField
                              name={`typeIntern-${i}`}
                              type='text'
                              helperText={'Bezeichnung intern'}
                              error={this.state.positions[i].typeIntern ? false :true}
                              value={this.state.positions[i].typeIntern || ''}
                              fullWidth
                              onChange={this.handlePosChange}
                              size='small'
                              // @ts-ignore
                              disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].readOnly}
                            />
                          </div>
                          <div>
                            <TextField
                              name={`trucksCount-${i}`}
                              type='number'
                              helperText={'Anzahl LKWs'}
                              error={this.state.positions[i].trucksCount ? false :true}
                              value={this.state.positions[i].trucksCount || ''}
                              fullWidth
                              onChange={this.handlePosChange}
                              size='small'
                              // @ts-ignore
                              disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].readOnly}
                            />
                          </div>
                          <div>
                            <TextField
                              name={`plannedEntryAmount-${i}`}
                              type='number'
                              helperText={'Geplanter Wareneingang'}
                              error={this.state.positions[i].plannedEntryAmount ? false :true}
                              value={this.state.positions[i].plannedEntryAmount || ''}
                              fullWidth
                              onChange={this.handlePosChange}
                              size='small'
                              InputProps={{
                                endAdornment: <InputAdornment position="end">{translate(this.state.positions[i].unit) || ''}</InputAdornment>
                              }}
                              // @ts-ignore
                              disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].readOnly}
                            />
                          </div>
                          <div>
                            <TextField
                              select
                              name={`packaging-${i}`}
                              error={this.state.positions[i].plannedEntryAmount ? false :true}
                              value={this.state.positions[i].packaging}
                              fullWidth
                              onChange={this.handlePosChange}
                              helperText='Verpackung'
                              size='small'
                              // @ts-ignore
                              disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].readOnly && this.state.positions[i].packaging}
                            >
                              <MenuItem key={'bale' + i} value='bale'>
                                Ballen
                              </MenuItem>
                              <MenuItem key={'bigbag' + i} value='bigbag'>
                                Bigbag
                              </MenuItem>
                              <MenuItem key={'divers' + i} value='divers'>
                                Divers
                              </MenuItem>
                            </TextField>
                          </div>
                        </div>
                        {this.props.modalMode === 'view' ? (
                          <div className="flex of-4 spacing-1" style={{marginTop: 15}}>
                            <div></div>
                            <div></div>
                            <div>
                              <TextField
                                value={`${localNumber(this.getPosData(this.state.positions[i]).deliveredAmount || 0)} ${this.state.positions[i].unit}`}
                                fullWidth
                                helperText='Menge geliefert'
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                              />
                            </div>
                            <div>
                              <TextField
                                value={`${localNumber(this.getPosData(this.state.positions[i]).difference || 0)} ${this.state.positions[i].unit}`}
                                fullWidth
                                helperText='Differenz'
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  <div style={{overflowX: 'scroll'}}>
                    <div style={{marginTop: 40}}>
                      <HeadingArrow text='LKW - Kennzeichen' />
                      <div className='truck-plates-table-row flex spacing-1'>
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={`strong text-center ${j === 0 ? 'first' : ''}`}>
                                P{i+1} / LKW{j+1}
                              </div>
                            )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className='truck-plates-table-row flex spacing-1'>
                        {/* <div className="row"><span>Kennzeichen</span></div> */}
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                          {(g.trucksCount && g.trucksCount >= 1 ? 
                          [...Array(parseInt(g.trucksCount))].map((t, j) => (
                            <div className={j === 0 ? 'first' : ''}>
                              <TextField
                                name={`truckPlate-${i}-${j}`}
                                type='text'
                                helperText={'Kennzeichen'}
                                //@ts-ignore
                                value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].truckPlate : ''}
                                fullWidth
                                onChange={this.handleTruckDataChange}
                                size='small'
                                //@ts-ignore
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                              />
                            </div>
                          )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className='truck-plates-table-row flex spacing-1'>
                        {/* <div className="row"><span>Warenmenge</span></div> */}
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  name={`truckAmount-${i}-${j}`}
                                  type='number'
                                  helperText={'Warenmenge'}
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].truckAmount : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">{translate(this.state.positions[i].unit) || ''}</InputAdornment>
                                  }}
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                                />
                              </div>
                            )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                      {this.props.modalMode === 'view' ? null : (
                        <div className='truck-plates-table-row flex spacing-1'>
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  name={`loadType-${i}-${j}`}
                                  select
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].loadType : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Ladungstyp'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                                >
                                  <MenuItem value='lkw'>LKW Ladung</MenuItem>
                                  <MenuItem value='container'>LKW mit Container</MenuItem>
                                </TextField>
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                      <div className='truck-plates-table-row flex spacing-1'>
                        {/* <div className="row"><span>Lieferdatum</span></div> */}
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                          {(g.trucksCount && g.trucksCount >= 1 ? 
                          [...Array(parseInt(g.trucksCount))].map((t, j) => (
                            <div className={j === 0 ? 'first' : ''}>
                              <TextField
                                name={`truckDeliveryDate-${i}-${j}`}
                                type='date'
                                //@ts-ignore
                                value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].truckDeliveryDate : ''}
                                fullWidth
                                onChange={this.handleTruckDataChange}
                                size='small'
                                helperText='Lieferdatum geplant'
                                //@ts-ignore
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                              />
                            </div>
                          )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                      {this.props.modalMode === 'view' ? null : (
                        <div className='truck-plates-table-row flex spacing-1'>
                          {/* <div className="row"><span>Lieferdatum</span></div> */}
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  name={`start-${i}-${j}`}
                                  type='time'
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].start : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Ankunft frühestens'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                                />
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                      {this.props.modalMode === 'view' ? null : (
                        <div className='truck-plates-table-row flex spacing-1'>
                          {/* <div className="row"><span>Lieferdatum</span></div> */}
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  name={`end-${i}-${j}`}
                                  type='time'
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].end : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Ankunft spätestens'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                                />
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                      {this.props.modalMode === 'view' ? null : (
                        <div className='truck-plates-table-row flex spacing-1'>
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  name={`reportsCount-${i}-${j}`}
                                  type='number'
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].reportsCount : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Anzahl Laborproben'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                                />
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                      )}
                    </div>
                    <div style={{marginTop: 40, paddingBottom: 20}}>
                      <HeadingArrow text='Lagerplatz' />
                      <div className='truck-plates-table-row flex spacing-1' style={{marginTop: 0}}>
                        {/* <div className="row"><span>Lagerplatz</span></div> */}
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                          {(g.trucksCount && g.trucksCount >= 1 ? 
                          [...Array(parseInt(g.trucksCount))].map((t, j) => (
                            <div className={j === 0 ? 'first' : ''}>
                              <TextField
                                select
                                name={`storagePlace-${i}-${j}`}
                                //@ts-ignore
                                value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].storagePlace : ''}
                                fullWidth
                                onChange={this.handleTruckDataChange}
                                helperText='Lagerplatz'
                                size='small'
                                //@ts-ignore
                                disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].fulfilled}
                              >
                                <MenuItem value=''>&nbsp;</MenuItem>
                                {this.props.warehouseSpots.map((spot, i) => (
                                  <MenuItem key={'spot' + i} value={spot.id}>
                                    {spot.attributes.name}{this.props.modalMode === 'view' ? null : ` - ${localNumber(spot.attributes.capacity || 0)} kg frei`}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    <div style={{marginTop: 40, paddingBottom: 20}}>
                      <HeadingArrow text='Lieferkosten & Co' />
                      <div className='truck-plates-table-row flex spacing-1' style={{marginTop: 0}}>
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                          {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''} style={{position: 'relative'}}>
                                <TextField
                                  name={`deliveryCosts-${i}-${j}`}
                                  type='number'
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].deliveryCosts : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Lieferkosten'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && (!(this.props.rights.includes('entry::price-update') && this.state.enableTotals))}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                  }}
                                />
                                {/* @ts-ignore */}
                                {(this.props.modalMode === 'view' || this.state.positions[i].readOnly) && this.props.rights.includes('entry::price-update') && (
                                  <span className='update-price' onClick={this.updateTotals}>
                                    {this.state.enableTotals ? 'speichern' : 'ändern'}
                                  </span>
                                )}
                              </div>
                            )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className='truck-plates-table-row flex spacing-1'>
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                          {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''} style={{position: 'relative'}}>
                                <TextField
                                  name={`customDuty-${i}-${j}`}
                                  type='number'
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].customDuty : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Zollgebühren'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && (!(this.props.rights.includes('entry::price-update') && this.state.enableTotals))}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                  }}
                                />
                                {/* @ts-ignore */}
                                {(this.props.modalMode === 'view' || this.state.positions[i].readOnly) && this.props.rights.includes('entry::price-update') && (
                                  <span className='update-price' onClick={this.updateTotals}>
                                    {this.state.enableTotals ? 'speichern' : 'ändern'}
                                  </span>
                                )}
                              </div>
                            )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                      <div className='truck-plates-table-row flex spacing-1'>
                        {this.state.positions.map((g, i) => (
                          <React.Fragment>
                          {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''} style={{position: 'relative'}}>
                                <TextField
                                  name={`deliveryCostsOther-${i}-${j}`}
                                  type='number'
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] ? this.state.positions[i].truckData[j].deliveryCostsOther : ''}
                                  fullWidth
                                  onChange={this.handleTruckDataChange}
                                  size='small'
                                  helperText='Andere'
                                  //@ts-ignore
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :this.state.positions[i].truckData && this.state.positions[i].truckData[j] && (!(this.props.rights.includes('entry::price-update') && this.state.enableTotals))}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                  }}
                                />
                                {/* @ts-ignore */}
                                {(this.props.modalMode === 'view' || this.state.positions[i].readOnly) && this.props.rights.includes('entry::price-update') && (
                                  <span className='update-price' onClick={this.updateTotals}>
                                    {this.state.enableTotals ? 'speichern' : 'ändern'}
                                  </span>
                                )}
                              </div>
                            )) : null)}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                    {this.props.modalMode === 'view' ? (
                      <div style={{marginTop: 40, paddingBottom: 20}}>
                        <HeadingArrow text='Soll-Ist-Vergleich' />
                        <div className='truck-plates-table-row flex spacing-1' style={{marginTop: 0}}>
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  name={`truckDeliveryDate-${i}-${j}`}
                                  //@ts-ignore
                                  value={this.state.positions[i].truckData && this.state.positions[i].truckData[j] && this.state.positions[i].truckData[j].actualDeliveryDate ? convertDateString(this.state.positions[i].truckData[j].actualDeliveryDate) : ''}
                                  fullWidth
                                  helperText='Lieferdatum tatsächlich'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className='truck-plates-table-row flex spacing-1'>
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  value={`${localNumber(this.getTruckData(g, j).deliveredAmount || 0)} ${this.state.positions[i].unit}`}
                                  fullWidth
                                  helperText='Menge geliefert'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                        <div className='truck-plates-table-row flex spacing-1'>
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                <TextField
                                  // @ts-ignore
                                  value={`${localNumber((this.getTruckData(g, j).deliveredAmount || 0) - (this.state.positions[i].truckData[j].truckAmount || 0))} kg`}
                                  fullWidth
                                  helperText='Differenz'
                                  disabled={this?.props?.user?.email === "ang@ksmrecycling.at" ? false :true}
                                />
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {this.props.modalMode === 'view' ? (
                      <div style={{marginTop: 40, paddingBottom: 20}}>
                        <HeadingArrow text='Anmerkungen' />
                        <div className='truck-plates-table-row flex spacing-1' style={{marginTop: 0}}>
                          {this.state.positions.map((g, i) => (
                            <React.Fragment>
                            {(g.trucksCount && g.trucksCount >= 1 ? 
                            [...Array(parseInt(g.trucksCount))].map((t, j) => (
                              <div className={j === 0 ? 'first' : ''}>
                                {this.getTruckData(g, j).notes ? this.getTruckData(g, j).notes : <i>keine Anmerkungen</i>}
                              </div>
                            )) : null)}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              {(this.props.modalMode === 'view' || this.props.modalMode === 'prepare-remaining') && (
                <div>
                  <hr />
                  <h2>Produktion</h2>
                  <h3>Waren-IDs</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>ID</TableCell>
                        <TableCell>Bezeichnung</TableCell>
                        <TableCell>Menge</TableCell>
                      </TableHead>
                      <TableBody>
                        {this.props.modalEditDraftData?.attributes.goods && this.props.modalEditDraftData?.attributes.goods.data && this.props.modalEditDraftData?.attributes.goods.data.map(good => (
                          <TableRow key={`good-1-${good.attributes.customId}`}>
                            <TableCell onClick={()=>this.props.handleIncommingPop(good)} ><strong >{good.attributes.customId}</strong></TableCell>
                            <TableCell>{good.attributes.typeIntern}</TableCell>
                            <TableCell>{good.attributes.origQuantity} {good.attributes.unit}</TableCell>
                          </TableRow>
                        ))}
                        {this.props.modalEditDraftData?.attributes.positions && this.props.modalEditDraftData?.attributes.positions.length >= 1 && this.props.modalEditDraftData?.attributes.positions.map(pos => (
                          <>
                            {pos.truckData && pos.truckData.length >= 1 && pos.truckData.map(truck => (
                              <>
                                {truck.deliveredPositions && truck.deliveredPositions.length >= 1 && truck.deliveredPositions.map(dPos => (
                                  <>
                                    {dPos.reports && dPos.reports.length >= 1 && dPos.reports.map(rep => (
                                      <TableRow key={`report-${rep.id}`}>
                                        <TableCell><Link to={`/labor?id=${rep.id}`}><strong>{rep.attributes.customId}</strong></Link></TableCell>
                                        <TableCell>Laborprobe</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                ))}
                              </>
                            ))}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <h3 style={{marginTop: 40}}>In Charge verarbeitet</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>ID</TableCell>
                        <TableCell>Status</TableCell>
                      </TableHead>
                      <TableBody>
                        {this.state.batches && this.state.batches.length >= 1 ?  this.state.batches.map((batch: Batch) => (
                          <TableRow key={`batch-1-${batch.attributes.customId}`}>
                            <TableCell><Link to={`/produktion/chargen?id=${batch.id}`}><strong>{batch.attributes.customId}</strong></Link></TableCell>
                            <TableCell>{translate(batch.attributes.status)}</TableCell>
                          </TableRow>
                        )) : (
                          <TableRow>
                            <TableCell>Keine Chargen gefunden</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <h3 style={{marginTop: 40}}>Warenausgang verbucht</h3>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Produkt</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableHead>
                      <TableBody>
                        {this.props.modalEditDraftData?.attributes.goods && this.props.modalEditDraftData?.attributes.goods.data && this.props.modalEditDraftData?.attributes.goods.data.map((good) => (
                          <>
                            {good.attributes.status !== 'stored' && (
                              <TableRow key={`order-1-${good.attributes.customId}`}>
                                <TableCell><Link to={`/lager/inventar?id=${good.id}`}>{good.attributes.customId}</Link></TableCell>
                                <TableCell>{translate(good.attributes.status)}</TableCell>
                                <TableCell>
                                  {good.attributes.status === 'sold' && good.attributes.exit_orders && good.attributes.exit_orders.data && good.attributes.exit_orders.data.length >= 1 && good.attributes.exit_orders.data.map(order => (
                                    <><Link to={`/warenausgang?id=${order.id}`}><strong>{order.attributes.customId}</strong></Link><br /></>
                                  ))}
                                  {good.attributes.status === 'used' && good.attributes.batches && good.attributes.batches.data && good.attributes.batches.data.length >= 1 && good.attributes.batches.data.map(batch => (
                                    <><Link to={`/produktion/chargen?id=${batch.id}`}><strong>{batch.attributes.customId}</strong></Link><br /></>
                                  ))}
                                  {good.attributes.customId.toLowerCase().startsWith('bm') && good.attributes.usedForBatches && good.attributes.usedForBatches.data && good.attributes.usedForBatches.data.length >= 1 && good.attributes.usedForBatches.data.map(batch => (
                                    <><Link to={`/produktion/chargen?id=${batch.id}`}><strong>{batch.attributes.customId}</strong></Link><br /></>
                                  ))}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}


              <div className="text-right" style={{marginTop: 30}}>
                {this.props.modalMode === 'updateDraft' && <Button variant='outlined' color='error' sx={{marginRight: '15px'}} onClick={() => this.cancelOrder(this.props.modalEditDraftData ? this.props.modalEditDraftData?.id : -1)}>Entwurf löschen</Button>}
                {this.props.modalMode === 'prepare' && <Button variant='outlined' color='error' sx={{marginRight: '15px'}} onClick={() => this.cancelOrder(this.props.modalEditDraftData ? this.props.modalEditDraftData?.id : -1)}>Bestellung stornieren</Button>}
                {(this.props.modalMode === 'create' || this.props.modalMode === 'updateDraft') && <Button variant='outlined' color='success' sx={{marginRight: '15px'}} onClick={this.props.modalEditDraftMode ? () => this.handleSaveOrder(true, this.props.modalEditDraftMode, this.props.modalEditDraftData?.id || -1) : () => this.handleSaveOrder(true)}>Als Entwurf speichern</Button>}
                {this.props.modalMode !== 'view' && <Button variant='contained' onClick={() => this.props.modalEditDraftMode ? this.handleSaveOrder(false, this.props.modalEditDraftMode, this.props.modalEditDraftData?.id || -1) : this.handleSaveOrder()}>Bestellung speichern</Button>}
                &nbsp; &nbsp;{
                  this.props.user.email === 'ang@ksmrecycling.at' &&
               <Button variant='outlined' color='success' 
                sx={{marginRight: '15px'}} 
                onClick={() => this.handleRestorOrder()} > Restore Order</Button>
                }
              </div>
            </div>
          </Box>
        </>
      </Modal>
    )
  }
};

export default NewOrderForm;
