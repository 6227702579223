import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import WarehouseExit from '../components/Warehouse/WarehouseExit/WarehouseExit';
import { UserStore } from '../stores/user';

export default function WarehouseExitRoute() {
  const user = UserStore((state: any) => state.user);
  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <WarehouseExit user={user}/>
    </main>
  )
}