import { Close, ExpandMore, QrCode2 } from '@mui/icons-material';
import { Box, Button, CircularProgress, InputAdornment, MenuItem, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import React from 'react';
// import { Link } from 'react-router-dom';
import { fetchWarehouseData, WarehouseSpot } from '../../../services/configService';
import { fetchSingleInventory, Good } from '../../../services/inventoryService';
import { resetNotifications } from '../../../services/notiStoreService';
import { convertDateString, convertGoodsCategory, convertOrderAttributes, localCurrency, localNumber, localNumberInTons, translate } from '../../../services/stringConverter';
import { NotiStore } from '../../../stores/notifications';
import './WarehouseStores.sass';
import { foodSafeOptions, materials, rpetColors } from '../../../utils/defaults';
import { Link } from 'react-router-dom';
import { ExitOrderAttributes } from '../../../services/warehouseExitService';

interface WarehouseStoresProps { }
interface WarehouseStoresState {
  activeGood: Good | undefined;
  showDetailPop: boolean;
  enableUpdate: string;
  updateData: { [key: string]: string | number };
  exitOrders: {
    id: number;
    attributes: ExitOrderAttributes;
  }[];
  spots: WarehouseSpot[];
  tableExpandedPos: number[];
  page: number;
  rowsPerPage: number;
  pagination: { page: number, pageCount: number, pageSize: number, total: number },
  // sort:string | null,
  search:string 
}

const defaultState: WarehouseStoresState = {
  activeGood: undefined,
  showDetailPop: false,
  enableUpdate: '',
  updateData: {},
  exitOrders: [],
  spots: [],
  tableExpandedPos: [-1],
  page: 0,
  rowsPerPage: 10,
  pagination: { page: 1, pageCount: 8, pageSize: 100, total: 100 },
  // sort:"name:asc",
  search:''
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden'
};

class WarehouseStores extends React.Component<WarehouseStoresProps, WarehouseStoresState> {
  state = { ...defaultState };

  componentDidMount() {
    resetNotifications()
    this.fetchData()
  }

  handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    this.setState({ page: this.state.page === 0 ? newPage + 1 : newPage }, this.fetchData)

  };

  handleFilter = (type:string,value:string): void => {
    // NotiStore.setState({ loading: true })
    if(type==='search'){
      this.setState({search:value})
      this.fetchData(value)
      
    }else{
      this.fetchData() 
    }

  }

  clearFilters = (): void => {
    // this.setState({ sort:'name:desc',search:'' })
    this.fetchData();
  }

  handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({ rowsPerPage: parseInt(event.target.value) });
    this.setState({ page: 0 }, this.fetchData)

  };

  fetchData = (search=''): void => {
    NotiStore.setState({ loading: true });
    fetchWarehouseData(`_q=${search}&pagination[page]=${this.state.page}&pagination[pageSize]=${this.state.rowsPerPage}`)
      .then(res => {
        let tableExpandedPos: number[] = [];
        if (res.spots && res.spots.length >= 1) res.spots.forEach(store => {
          let storeGoods: Good[] = []
          this.setState({ pagination: res.meta.pagination })
          if (store.attributes.goods && store.attributes.goods.data) store.attributes.goods.data.forEach(good => {
            if (good.attributes.status === 'stored') storeGoods.push(good)
          })
          store.attributes.goods = { data: storeGoods }
        })

        // if (res.spots && res.spots.length >= 1) res.spots.forEach((o, i) => tableExpandedPos.push(i))

        if (res.spots && res.spots.length >= 1) this.setState({ spots: res.spots, tableExpandedPos });
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: 'Fehler beim Abrufen der Lagerdaten aufgetreten.' })
      })
      .finally(() => {
        NotiStore.setState({ loading: false })
      })
  }

  expand = (i: number): void => {
    if (i >= 0) {
      let { tableExpandedPos } = this.state

      if (tableExpandedPos.includes(i)) {
        let index = tableExpandedPos.indexOf(i)

        if (index > -1) {
          tableExpandedPos.splice(index, 1)
        }
      } else {
        tableExpandedPos.push(i)
      }

      this.setState({ tableExpandedPos })
    }
  }

  // TODO: only stored products are shown -> implement fetchProduct
  showProductDetail = (id: number): void => {
    resetNotifications()
    NotiStore.setState({ loading: true })
    fetchSingleInventory(id)
      .then(res => {
        this.setState({
          activeGood: res.data,
          showDetailPop: true
        })
      })
      .catch(err => {
        NotiStore.setState({ error: true, errorMessage: err.message || 'Fehler beim Abrufen der Produktdaten.' })
      })
      .finally(() => NotiStore.setState({ loading: false }))
  }

  onCloseDetailPop = (): void => {
    this.setState({
      activeGood: defaultState.activeGood,
      showDetailPop: defaultState.showDetailPop,
    })
  }

  checkExitDate = (id: string): string => {
    let date: string = ''

    // if (exitOrders.length >= 1) {
    //   exitOrders.forEach(order => {
    //     let { positions } = order.attributes

    //     if (positions && positions.length >= 1) positions.forEach(pos => {
    //       if (pos.trucks && pos.trucks.length >= 1) pos.trucks.forEach(truck => {
    //         if (truck.goods.includes(id)) {
    //           if (truck.dispatchDate) date = truck.dispatchDate.toString()
    //           else date = 'n/a'
    //         }
    //       })
    //     })
    //   })
    // }

    return date ? convertDateString(date) : 'nicht geplant'
  }

  render() {
    return (
      <div className="WarehouseStores content" data-testid="WarehouseStores">
        <div className="header-bar">
          <h1>Lagerplätze</h1>
        </div>
        <div style={{ marginTop: 10,marginBottom:10 }}>
            <TextField value={this.state.search} placeholder='Search...' onChange={(e)=>this.handleFilter('search',e.target.value)} />
            {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Select
            value={this.state.sort}
            input={<OutlinedInput />}
            onChange={(e) => this.setState({sort:e.target.value})}
            onClose={()=>this.handleFilter('sort','')}
            sx={{ width: '300px' }}
          >
           
            {InvetorySort.map((status,i) => (
              <MenuItem key={i} value={status.value}>
                {status.Label}
              </MenuItem>
            ))}

          </Select>&nbsp;&nbsp;
          <Button onClick={()=>this.handleFilter('sort','')} variant='contained'>Sort</Button>&nbsp;&nbsp;
          <Button onClick={()=>this.clearFilters()} variant='contained' color="error">Clear</Button> */}
          </div>
        {this.state.spots.length >= 1 ? (
          <TableContainer component={Paper} className='spots-table'>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Lager</TableCell>
                  <TableCell>Bezeichnung</TableCell>
                  <TableCell>Positionen</TableCell>
                  <TableCell>Gesamtkapazität</TableCell>
                  <TableCell>Belegt</TableCell>
                  <TableCell>Freie Kapazität</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.spots
                .sort((a:any, b:any) => a.attributes.name.localeCompare(b.attributes.name))
                .map((spot: WarehouseSpot, i: number) => (
                  <React.Fragment key={spot.attributes.name}>
                    <TableRow>
                      <TableCell sx={{ width: '60px' }}>
                        {spot.attributes.goods && spot.attributes.goods.data && spot.attributes.goods.data.length >= 1 ? <ExpandMore className={`pointer`} style={this.state.tableExpandedPos.includes(i) ? { transform: 'rotate(270deg)' } : {}} onClick={() => this.expand(i)} /> : ''}
                      </TableCell>
                      <TableCell><strong>{spot.attributes.name}</strong></TableCell>
                      <TableCell>{spot.attributes.description || ''}</TableCell>
                      <TableCell><i>{spot.attributes.goods && spot.attributes.goods.data && spot.attributes.goods.data.length >= 1 ? (spot.attributes.goods.data.length + ' Positionen') : 'leer'}</i></TableCell>
                      <TableCell>{localNumberInTons(spot.attributes.totalCapacity || 0)} t</TableCell>
                      <TableCell>{localNumberInTons(spot.attributes.totalCapacity - spot.attributes.capacity)} t</TableCell>
                      <TableCell>{localNumberInTons(spot.attributes.capacity || 0)} t</TableCell>
                    </TableRow>
                    {this.state.tableExpandedPos.includes(i) && spot.attributes.goods && spot.attributes.goods.data && spot.attributes.goods.data.length >= 1 && (
                      <TableRow sx={{ background: '#F4F4F4' }}>
                        <TableCell style={{ padding: '20px 20px 20px 60px' }} colSpan={8}>
                          <Table stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>ID</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Bezeichnung</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Kategorie</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Menge</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Eingang</strong></TableCell>
                                <TableCell sx={{ background: '#f4f4f4' }}><strong>Verarbeitung</strong></TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {spot.attributes.goods && spot.attributes.goods.data ? spot.attributes.goods.data.map((good: Good, j: number) => good.attributes.status === 'stored' && (
                                <TableRow key={`stored-${good.id}`}>
                                  <TableCell onClick={() => this.showProductDetail(good.id)}>
                                    {/* <Link to={`/lager/inventar?id=${good.id}`}> */}
                                    <span className='pointer'>{good.attributes.customId}</span>
                                    {/* </Link> */}
                                  </TableCell>
                                  <TableCell>{good.attributes.typeIntern}</TableCell>
                                  <TableCell>{convertGoodsCategory(good.attributes.category)}</TableCell>
                                  <TableCell>{good.attributes.unit === 'kg' ? localNumberInTons(good.attributes.quantity) : localNumber(good.attributes.quantity)} {good.attributes.unit === 'kg' ? 't' : good.attributes.unit}</TableCell>
                                  <TableCell>{convertDateString(good.attributes.createdAt)}</TableCell>
                                  <TableCell>
                                    {good.attributes.batches && good.attributes.batches.data && good.attributes.batches.data.length >= 1 ? good.attributes.batches.data.map((batch) => (
                                      <React.Fragment key={good.id + batch.id}><span>{batch.attributes.start ? `${new Date(batch.attributes.start).toLocaleDateString()} ${new Date(batch.attributes.start).toLocaleTimeString().replace(':00', '')}` : 'n/a'}</span><br /></React.Fragment>
                                    )) : 'nicht verplant'}
                                  </TableCell>
                                </TableRow>
                              )) : null}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableCell>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="70vh"
              paddingLeft={80}
            >
              <CircularProgress />
            </Box>


          </TableCell>
          // <Paper style={{padding: '20px'}}>Keine Lagerplätze gefunden.</Paper>
        )}
        {
          this.state.spots.length >= 1 && 
        <TablePagination
          component={"div"}
          count={this.state.pagination.total}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
        }
        <Modal
          open={this.state.showDetailPop && this.state.activeGood !== undefined}
          onClose={this.onCloseDetailPop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="detail-pop"
        >
          <>
            <span className='modal-close pointer' onClick={this.onCloseDetailPop}><Close /></span>
            <Box sx={modalStyle}>
              <span className='modal-close pointer' onClick={this.onCloseDetailPop}><Close /></span>
              <div className="header-bar">
                <h2>Detailansicht</h2>
                {this.state.activeGood && this.state.activeGood?.attributes.label && this.state.activeGood?.attributes.label.data ? (
                  <div><a href={this.state.activeGood.attributes.label.data.attributes.url} target='_blank' rel='noreferrer' style={{ color: 'white' }}><Button variant='contained'><QrCode2 /></Button></a></div>
                ) : null}
              </div>
              <h3>Grunddaten</h3>
              <div className="flex of-4 spacing-1">
                <TextField
                  helperText='ID'
                  value={this.state.activeGood?.attributes.customId || 'nicht gefunden'}
                  disabled
                />
                <TextField
                  helperText='Kategorie'
                  value={this.state.activeGood?.attributes.category ? convertGoodsCategory(this.state.activeGood?.attributes.category) : 'nicht gefunden'}
                  disabled
                />
                <div style={{ position: 'relative' }}>
                  <TextField
                    helperText='Bezeichnung'
                    value={this.state.activeGood?.attributes.typeIntern || 'nicht gefunden'}
                    name='typeIntern'
                    disabled={this.state.enableUpdate !== 'typeIntern'}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                    fullWidth
                  />
                  {/* <span className='update-field' onClick={() => this.handleUpdate('typeIntern')}>
                    {this.state.enableUpdate === 'typeIntern' ? 'speichern' : 'ändern'}
                  </span> */}
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'color' ? (
                    <TextField
                      helperText='Farbe'
                      value={this.state.activeGood?.attributes.color || ''}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      name='color'
                    >
                      {rpetColors.map(value => (
                        <MenuItem key={`color-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Farbe'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.color || 'n/a')}
                      disabled
                      fullWidth
                    />
                  )}
                  {/* <span className='update-field' onClick={() => this.handleUpdate('color')}>
                    {this.state.enableUpdate === 'color' ? 'speichern' : 'ändern'}
                  </span> */}
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'foodSafe' ? (
                    <TextField
                      helperText='LME'
                      value={this.state.activeGood?.attributes.foodSafe || ''}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='foodSafe'
                    >
                      {foodSafeOptions.map(value => (
                        <MenuItem key={`foodSafe-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='LME'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.foodSafe || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  {/* <span className='update-field' onClick={() => this.handleUpdate('foodSafe')}>
                    {this.state.enableUpdate === 'foodSafe' ? 'speichern' : 'ändern'}
                  </span> */}
                </div>
                <div style={{ position: 'relative' }}>
                  {this.state.enableUpdate === 'material' ? (
                    <TextField
                      helperText='Material'
                      value={this.state.activeGood?.attributes.material || ''}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                      fullWidth
                      select
                      sx={{ marginTop: '15px' }}
                      name='material'
                    >
                      {materials.map(value => (
                        <MenuItem key={`material-${value}`} value={value}>{convertOrderAttributes(value)}</MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <TextField
                      helperText='Material'
                      value={convertOrderAttributes(this.state.activeGood?.attributes.material || 'n/a')}
                      disabled
                      sx={{ marginTop: '15px' }}
                      fullWidth
                    />
                  )}
                  {/* <span className='update-field' onClick={() => this.handleUpdate('material')}>
                    {this.state.enableUpdate === 'material' ? 'speichern' : 'ändern'}
                  </span> */}
                </div>
                <TextField
                  helperText='integriert von'
                  value={this.state.activeGood?.attributes.creator.data?.attributes.name || '???'}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
                <TextField
                  helperText='Status'
                  value={translate(this.state.activeGood?.attributes.status || '???')}
                  disabled
                  sx={{ marginTop: '15px' }}
                />
              </div>
              <h3>Lager</h3>
              <div className="flex of-4 spacing-1">
                <div style={{ position: 'relative' }}>
                  <TextField
                    type={this.state.enableUpdate === 'quantity' ? 'number' : 'text'}
                    helperText='Menge'
                    value={this.state.enableUpdate === 'quantity' ? Number(this.state.activeGood?.attributes.quantity || 0) : (localNumber(this.state.activeGood?.attributes.quantity || '') || 'nicht gefunden')}
                    fullWidth
                    name='quantity'
                    disabled={this.state.enableUpdate !== 'quantity'}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange(e)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{this.state.activeGood?.attributes.unit || ''}</InputAdornment>
                    }}
                  />
                  {/* <span className='update-field' onClick={() => this.handleUpdate('quantity')}>
                    {this.state.enableUpdate === 'quantity' ? 'speichern' : 'ändern'}
                  </span> */}
                </div>
                <TextField
                  helperText='Wert'
                  value={(this.state.activeGood?.attributes.baseValue ? localCurrency((this.state.activeGood?.attributes.baseValue) * this.state.activeGood?.attributes.quantity) : 0)}
                  disabled
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
                <TextField
                  helperText='erstellt am'
                  value={this.state.activeGood?.attributes.createdAt ? convertDateString(this.state.activeGood?.attributes.createdAt) : 'nicht gefunden'}
                  disabled
                />
              </div>

              <h3>Dokumente</h3>
              <div className="flex of-4 spacing-1 documents">
                <Link to={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch.data ? `/produktion/chargen?id=${this.state.activeGood.attributes.fromBatch.data.id}` : '#'}>
                  <TextField
                    helperText='ID Charge'
                    value={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch.data ? this.state.activeGood?.attributes.fromBatch.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.fromBatch && this.state.activeGood?.attributes.fromBatch.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order.data ? `/wareneingang?id=${this.state.activeGood.attributes.order.data.id}` : '#'}>
                  <TextField
                    helperText='ID Bestellung'
                    value={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order.data ? this.state.activeGood?.attributes.order.data.attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.order && this.state.activeGood?.attributes.order.data ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                <Link to={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes ? `/warenausgang?id=${this.state.activeGood?.attributes.exit_orders.data[0].id}` : '#'}>
                  <TextField
                    helperText='ID Auftrag'
                    value={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes && this.state.activeGood?.attributes.exit_orders.data[0].attributes.customId ? this.state.activeGood?.attributes.exit_orders.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.exit_orders && this.state.activeGood?.attributes.exit_orders.data && this.state.activeGood?.attributes.exit_orders.data[0] && this.state.activeGood?.attributes.exit_orders.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
                </Link>
                  <TextField
                    helperText='Laborbericht'
                    value={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId ? this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId : '-'}
                    disabled
                    className='text-center'
                    fullWidth
                    inputProps={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? { style: { fontWeight: 'bold' } } : {}}
                  />
              </div>
              {
                this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId&&
                <div className='flex of-4 spacing-1 documents'>
                  <Link to={this.state.activeGood?.attributes.laboratory_reports && this.state.activeGood?.attributes.laboratory_reports.data && this.state.activeGood?.attributes.laboratory_reports.data[0] && this.state.activeGood?.attributes.laboratory_reports.data[0].attributes ? `/labor?id=${this.state.activeGood?.attributes.laboratory_reports.data[0].id}` : '#'}>
                    <Button  sx={{ marginTop: '15px' }}className="pt-5" variant='contained'>Go {this.state.activeGood?.attributes.laboratory_reports.data[0].attributes.customId}</Button>
                  </Link>
                </div>
              }

              <h3>Status</h3>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Aktueller Status</TableCell>
                      <TableCell align='right'>{translate(this.state.activeGood?.attributes.status || 'nicht bekannt')}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Geplante Abholung (Warenausgang)</TableCell>
                      <TableCell align='right'>{this.state.activeGood?.id ? this.checkExitDate(this.state.activeGood?.attributes.customId) : '???'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <h3>Status</h3> */}
              {/* //TODO */}
            </Box>
          </>
        </Modal>
      </div>
    )
  }
}

export default WarehouseStores;

