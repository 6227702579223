import Sidebar from '../components/Sidebar/Sidebar';
import TopNav from '../components/TopNav/TopNav';
import WarehouseEntry from '../components/Warehouse/WarehouseEntry/WarehouseEntry';
import { UserStore } from '../stores/user';

export default function WarehouseEntryRoute() {
  const user = UserStore((state: any) => state.user);

  return (
    <main className='Warehouse container'>
      <Sidebar />
      <TopNav />
      <WarehouseEntry user={user} />
    </main>
  )
}