import { getJWT, jwtRes } from "./jwtService";
import { api } from "./apiService";
import axios from "axios";
import { WarehouseSpot } from "./configService";
import { Report } from "./laboratoryService";
import { Batch } from "./batchesService";
import { UserData } from "./userService";
import { OrderData } from "./warehouseEntriesService";
import { ExitOrderAttributes } from "./warehouseExitService";

export interface fetchInventoryRes {
  success: boolean;
  message: string;
  data: Good[],
  meta:metaResponse

}
export interface metaResponse {
  pagination: { page: number; pageCount: number; pageSize: number; total: number; };
   page: number, pageCount : number ,pageSize : number ,total :  number
}
export interface fetchSingleInventoryRes {
  success: boolean;
  message: string;
  data: Good;
}

export interface Good {
  id: number;
  attributes: {
    customId: string;
    category: string;
    typeIntern: string;
    quantity: number;
    origQuantity: number;
    unit: string;
    baseValue: number;
    baseValueWithDelivery: number;
    status: string;
    createdAt: string;
    warehouse_spot: {data: WarehouseSpot};
    laboratory_reports: {data: Report[]};
    batches: {data: Batch[]};
    fromBatch: {data: Batch};
    color: string;
    foodSafe: string;
    material: string;
    label: {
      data: {
        attributes: {
          url: string;
        }
      }
    };
    creator: {
      data: {
        attributes: UserData
      }
    };
    exit_orders: {
      data: {
        id: number,
        attributes: ExitOrderAttributes
      }[]
    };
    order: {data: {attributes: OrderData, id: number}};
    usedForBatches: {data: Batch[]}
  }
}

export interface FilteredGood extends Good {
  hidden?: boolean;
}

export let fetchInvetory = (filters: string = ''): Promise<fetchInventoryRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.get(api + `/api/goods?sort[0]=customId%3Adesc${filters ? '&' + filters : ''}&populate=*`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data,
          message: '',
          meta:res.data.meta,
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: []
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}
export let fetchWareHourseSpotHistory = (filters: string = ''): Promise<fetchInventoryRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.get(api + `/api/warehourse-spot-histories${filters}`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data,
          message: '',
          meta:res.data.meta,
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: []
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let fetchSingleInventory = (id: number | string): Promise<fetchSingleInventoryRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.get(api + `/api/goods/${id}?populate=*`, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data.data,
          message: ''
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: []
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export let fetchInvetoryWithFilters = (filters: {[key: string]: string[]}, sortBy: {[key: string]: string} = {}): Promise<fetchInventoryRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: []
      })
      
      //do stuff here
      axios.post(api + `/api/goods/custom-filter`, {data: {filters, sortBy}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data || [],
          message: '',
          meta:res.data.meta
        })
      })
      .catch(err => {
        reject({
          success: false,
          data: [],
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Abrufen der Daten.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: []
      })
    })
  });
}

export interface createProductRes {
  success: boolean;
  message: string;
  data: Good | undefined;
}

export interface CreateProductData {
  category: string;
  typeIntern: string;
  color: string;
  foodSafe: string;
  material: string;
  packaging: string;
  quantity: number;
  unit: string;
  baseValue: number;
  entryDate: string;
  warehouse_spot: number;
  batch: number;
  order: number;
  truck: string;
  dPos: string;
}

export let createProduct = (data: CreateProductData): Promise<createProductRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: undefined
      })

      let err: string = ''
      let { category, typeIntern, color, foodSafe, material, packaging, quantity, unit, entryDate, warehouse_spot, batch, order, truck, dPos } = data
      if (!category) {
        err = "Bitte gib eine Kategorie an."
      } else if (!typeIntern) {
        err = "Bitte gib eine Bezeichnung an."
      } else if (category !== 'bm' && !color) {
        err = "Bitte gib eine Farbe an."
      } else if (category !== 'bm' && !foodSafe) {
        err = "Bitte gib an, ob das Produkt lebensmittelecht ist."
      } else if (category !== 'bm' && !material) {
        err = "Bitte gib ein Material an."
      } else if (!quantity) {
        err = "Bitte gib die Menge an."
      } else if (!unit) {
        err = "Bitte gib eine Einheit an."
      } else if (!entryDate) {
        err = "Bitte gib das Datum des Wareneingangs an."
      } else if (!packaging) {
        err = "Bitte gib die Verpackungsart an."
      } else if (!warehouse_spot) {
        err = "Bitte gib einen Lagerplatz an."
      } else if ((category === 'rpet' || category === 'cw' || category === 'nm' || category === 'd') && !batch) {
        err = "Bitte gib die Charge an, aus der das Produkt entstanden ist."
      } else if ((category === 'r' || category === 'bm' || category === 'hw' || category === 'zl') && !order) {
        err = "Bitte gib die Bestellung an, mit welcher das Produkt bestellt wurde."
      } else if ((category === 'r' || category === 'bm' || category === 'hw' || category === 'zl') && !truck) {
        err = "Bitte gib den LKW an, mit welchem das Produkt geliefert wurde."
      } else if ((category === 'r' || category === 'bm' || category === 'hw' || category === 'zl') && !(dPos)) {
        err = "Bitte gib an, welcher Position das Produkt zugewiesen werden soll."
      }

      if (err) return reject({
        success: false,
        data: undefined,
        message: err
      })
      
      axios.post(api + `/api/goods/create-custom`, {data}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: res.data || undefined,
          message: 'Produkt erfolgreich angelegt.'
        })
      })
      .catch(err => {
        console.log(err)
        reject({
          success: false,
          data: undefined,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Anlegen des Produktes.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: undefined
      })
    })
  });
}

export let deleteProduct = (id: number): Promise<createProductRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: undefined
      })
      
      axios.post(api + `/api/goods/delete-custom`, {data: {id}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        resolve({
          success: true,
          data: undefined,
          message: 'Produkt erfolgreich gelöscht.'
        })
      })
      .catch(err => {
        console.log(err)
        reject({
          success: false,
          data: undefined,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Löschen des Produktes.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: undefined
      })
    })
  });
}

export let updateWarehouseSpot = (id: number, newSpot: string): Promise<createProductRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: undefined
      })
      
      axios.post(api + `/api/goods/update-warehouse-spot`, {data: {id, newSpot}}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        console.log(res)

        resolve({
          success: true,
          data: res.data || undefined,
          message: 'Produkt erfolgreich aktualisiert.'
        })
      })
      .catch(err => {
        console.log(err)
        reject({
          success: false,
          data: undefined,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Aktualisieren des Produktes.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: undefined
      })
    })
  });
}

export let updateGood = (updateData: {[key: string]: string | number}): Promise<createProductRes> => {
  return new Promise((resolve, reject) => {
    getJWT()
    .then((jwtRes: jwtRes) => {
      if (!jwtRes.jwt) reject({
        success: false,
        message: 'no jwt found',
        data: undefined
      })
      
      axios.put(api + `/api/goods/custom-update`, {data: updateData}, {headers: {'Authorization': `Bearer ${jwtRes.jwt}`}})
      .then((res) => {
        console.log(res)

        resolve({
          success: true,
          data: res.data || undefined,
          message: 'Produkt erfolgreich aktualisiert.'
        })
      })
      .catch(err => {
        console.log(err)
        reject({
          success: false,
          data: undefined,
          message: err.response.data.error && err.response.data.error.message ? err.response.data.error.message :'Fehler beim Aktualisieren des Produktes.'
        })
      })
    })
    .catch((err) => {
      reject({
        success: false,
        message: 'JWT error',
        data: undefined
      })
    })
  });
}